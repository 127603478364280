import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "@apollo/client";
import { endpoint } from "./endpoint";

const httpLink = new HttpLink({
    uri: endpoint
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink])
});
