/* eslint-disable react/jsx-pascal-case */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Block, Button, Text, View } from "vcc-ui";
import video2 from "../../../assets/videos/notification_render_001.mp4";
import poster2 from "../../../assets/videos/notification_render_001_poster.jpg";
import video1 from "../../../assets/videos/pst-short_final.mp4";
import poster1 from "../../../assets/videos/pst-short_final_poster.jpg";
import video3 from "../../../assets/videos/watch_render_002.mp4";
import poster3 from "../../../assets/videos/watch_render_002_poster.jpg";
import { Video } from "./Video";

export function VideoGrid() {
    const { t } = useTranslation();

    return (
        <View
            margin={0}
            padding={[2, 2, 8]}
            justifyContent="center"
            alignItems="center"
            extend={{
                backgroundColor: "#fafafa",
                marginBottom: "68px",
                fromM: { marginBottom: "100px" }
            }}
        >
            <View
                spacing={10}
                maxWidth={1200}
                extend={{
                    paddingBottom: "68px",
                    fromM: { paddingBottom: "100px" }
                }}
            >
                <Block
                    extend={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "8px",
                        fromM: {
                            gridTemplateColumns: "588fr 442fr"
                        }
                    }}
                >
                    <Video
                        src={video1}
                        poster={poster1}
                        extend={{ gridColumn: "1/-1" }}
                        loop={true}
                    />
                    <Video src={video2} poster={poster2} loop={false} />
                    <Video src={video3} poster={poster3} loop={false} />
                </Block>

                <Block
                    extend={{
                        width: "100%",
                        display: "grid",
                        justifyContent: "center",
                        gridTemplateColumns: "1fr",
                        gap: "32px",
                        fromM: {
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gap: "80px"
                        }
                    }}
                >
                    <View spacing={3} justifyContent="space-between">
                        <View spacing={1} alignItems="center">
                            <Text variant="hillary" subStyle="emphasis">
                                {t("landingPage.videoGrid.1.title")}
                            </Text>
                            <Text extend={{ maxWidth: "300px !important" }}>
                                {t("landingPage.videoGrid.1.paragraph")}
                            </Text>
                        </View>
                        <View extend={{ margin: "auto" }}>
                            <Link to="/varfor-volvo-service">
                                <Button>
                                    {t("landingPage.videoGrid.1.cta")}
                                </Button>
                            </Link>
                        </View>
                    </View>

                    <View spacing={3} justifyContent="space-between">
                        <View spacing={1} alignItems="center">
                            <Text variant="hillary" subStyle="emphasis">
                                {t("landingPage.videoGrid.2.title")}
                            </Text>
                            <Text extend={{ maxWidth: "300px !important" }}>
                                {t("landingPage.videoGrid.2.paragraph")}
                            </Text>
                        </View>
                        <View extend={{ margin: "auto" }}>
                            <Link to="/inkopsansvar">
                                <Button>
                                    {t("landingPage.videoGrid.2.cta")}
                                </Button>
                            </Link>
                        </View>
                    </View>

                    <View spacing={3} justifyContent="space-between">
                        <View spacing={1} alignItems="center">
                            <Text variant="hillary" subStyle="emphasis">
                                {t("landingPage.videoGrid.3.title")}
                            </Text>
                            <Text extend={{ maxWidth: "300px !important" }}>
                                {t("landingPage.videoGrid.3.paragraph")}
                            </Text>
                        </View>
                        <View extend={{ margin: "auto" }}>
                            <Link to="/servicetekniker">
                                <Button>
                                    {t("landingPage.videoGrid.3.cta")}
                                </Button>
                            </Link>
                        </View>
                    </View>
                </Block>
            </View>
        </View>
    );
}
