import React from 'react';
import { useTranslation } from "react-i18next";
import { Text } from "vcc-ui";
import styled from "@emotion/styled";
import { Hero } from "../../components/Hero";
import { LoadingPage } from "../../components/LoadingPage";
import { useQuery } from "@apollo/client";
import { GET_FAQ } from "../../graphql/queries";
import * as Showdown from "showdown";

const Container = styled.div`
    padding: 20px;
    padding-bottom: 50px;
    > p:first-of-type {
        font-size: 13px;
        color: var(--gray3);
        text-align: center;
        margin-bottom: 20px;
        font-size: 700;
    }
    .questions {
        margin-bottom: 120px;
    }
    .heroWrapper {
        h1 {
            @media screen and (min-width: 769px) {
                max-width: 500px !important;
            }
        }
    }
`;

const Question = styled.div`
    margin-bottom: 35px;
    text-align: center;
    h4 {
        font-size: 24px;
        cursor: pointer;
        line-height: normal;
    }
`;

const Answer = styled.div`
    margin-bottom: 50px;
    h4 {
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        font-weight: 700;
        max-width: 680px;
    }
    p {
        font-size: 20px;
    }
    .question {
        p {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
    .answer {
        h4,
        h5 {
            font-weight: normal;
            margin: 0 0 8px 0;
        }
        p {
            margin-top: 0;
        }
    }
`;

export const FAQ = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    const { data, loading, error } = useQuery(GET_FAQ, {
        variables: { language: currentLang }
    });

    let refs = [];

    const scrollToElement = (ref) => {
        const offset = -60;
        const target = ref.current.offsetTop + offset;

        window.scrollTo({ top: target, behavior: "smooth" });
    };

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    if (loading) {
        return <LoadingPage />;
    }

    if (error || !data) {
        console.log(error);
        return <Text>Failed to fetch data</Text>;
    }

    return (
        <Container>
            <div className="heroWrapper">
                <Hero
                    title="Vanliga frågor och svar"
                    marginBottom="91px"
                    headerWidth="300px"
                />
            </div>

            <Text subStyle="emphasis" as="p">
                KOM IGÅNG
            </Text>

            <div className="questions">
                {data.pst.faq.map((val, i) => (
                    <Question key={i}>
                        <Text
                            subStyle="emphasis"
                            as="h4"
                            onClick={() => scrollToElement(refs[i])}
                        >
                            {val.question}
                        </Text>
                    </Question>
                ))}
            </div>

            <div className="answers">
                {data.pst.faq.map((val, i) => {
                    const newRef = React.createRef();
                    refs.push(newRef);

                    return (
                        <Answer key={i}>
                            <Text
                                variant="columbus"
                                as="h4"
                                className="question"
                                ref={newRef}
                            >
                                {val.question}
                            </Text>

                            <Text variant="columbus" as="p" className="answer">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(val.answer)
                                    }}
                                />
                            </Text>
                        </Answer>
                    );
                })}
            </div>
        </Container>
    );
}
