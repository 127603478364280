import { gql } from "@apollo/client";

export const GET_GUIDES = gql`
    query($language: String!) {
        pst(language: $language) {
            guide {
                title
                description
                image
                video
                category
            }
        }
    }
`;

export const GET_START = gql`
    query($language: String!) {
        pst(language: $language) {
            start {
                title
                description
                image
            }
        }
    }
`;

export const GET_FAQ = gql`
    query($language: String!) {
        pst(language: $language) {
            faq {
                question
                answer
            }
        }
    }
`;

export const GET_RELEASE = gql`
    query($language: String!) {
        pst(language: $language) {
            release {
                title
                description
            }
        }
    }
`;

export const GET_PINGPILOT = gql`
    query ($language: String!) {
        pst(language: $language) {
            pingpilot {
                title
                description
                category
                image
                video
            }
        }
    }
`;