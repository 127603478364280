import React from "react";
import { View } from "vcc-ui";

export const PinnedView = React.forwardRef(
    (
        {
            totalViewportHeights,
            children
        }: {
            totalViewportHeights: number;
            children: JSX.Element;
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <View
                ref={ref}
                extend={{
                    margin: 0,
                    padding: 0,
                    height: totalViewportHeights * 100 + "vh",
                    position: "relative"
                }}
            >
                <View
                    extend={{
                        height: 100 + "vh",
                        position: "sticky",
                        top: "60px"
                    }}
                >
                    {children}
                </View>
                <View
                    extend={{
                        height:
                            Math.max(totalViewportHeights - 1, 1) * 100 + "vh"
                    }}
                />
            </View>
        );
    }
);
