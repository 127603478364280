import { useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import { Block, CurrentTheme, ExtendPropValue, Icon, View } from "vcc-ui";

export function Video({
    src,
    poster,
    extend,
    loop
}: {
    src: string;
    poster: string;
    extend?: ExtendPropValue<CurrentTheme, {}>;
    loop: boolean;
}) {
    const videoElement = useRef<HTMLVideoElement>(null);
    const [playing, setPlaying] = useState(true);

    function handleClick(e: React.MouseEvent<HTMLVideoElement>) {
        if (e.currentTarget.paused) {
            e.currentTarget.play();
            setPlaying(true);
        } else {
            e.currentTarget.pause();
            setPlaying(false);
        }
    }

    useEffect(() => {
        if (videoElement.current) {
            if (videoElement.current.paused) {
                setPlaying(false);
            } else {
                setPlaying(true);
            }
        }
    }, [videoElement]);

    function handleEnter() {
        if (videoElement.current) {
            if (videoElement.current.paused) {
                setPlaying(false);
            } else {
                setPlaying(true);
            }
        }
    }

    return (
        <Block extend={{ position: "relative", ...extend }}>
            <Waypoint onEnter={handleEnter} bottomOffset="100px" />
            <Block
                ref={videoElement}
                onClick={handleClick}
                as="video"
                width="100%"
                poster={poster}
                controls={false}
                muted
                autoPlay
                loop={loop}
                playsInline
            >
                <Block as="source" src={src} type="video/mp4" />
            </Block>
            <PlaybackControl playing={playing} />
        </Block>
    );
}
function PlaybackControl({ playing }) {
    return (
        <View
            margin={[1, 2, 3]}
            extend={{
                position: "absolute",
                right: 0,
                bottom: 0,
                pointerEvents: "none"
            }}
        >
            {playing ? (
                <Icon type="media-pause-32" color="inverted" />
            ) : (
                <Icon type="media-play-32" color="inverted" />
            )}
        </View>
    );
}
