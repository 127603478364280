import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Block, Button, Text } from "vcc-ui";
import AppImg from "../../assets/getAppImg.png";

const Container = styled.div``;

const Steps = styled.div`
    .step {
        margin: auto;
        padding: 0 16px;
        padding-bottom: 100px;
        text-align: center;
        @media screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
        &:first-of-type {
            padding-top: 160px;
            @media screen and (max-width: 768px) {
                padding-top: 140px;
            }
        }
        &:last-of-type {
            padding-bottom: 100px;
            @media screen and (max-width: 768px) {
                padding-bottom: 70px;
            }
        }
        h2 {
            margin: 30px auto 20px auto;
        }
        p {
            /* max-width: 315px; */
            margin: auto;
            white-space: pre-line;
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }
        button {
            margin: 30px 0 10px 0;
        }
        .circle {
            border: 2px solid black;
            border-radius: 50%;
            width: 1.4em;
            height: 1.4em;
            padding: 5px;
            display: flex;
            margin: auto;
            padding: 0;
            p {
                line-height: 0;
            }
        }
    }
`;

const Questions = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    background-color: #fafafa;
    text-align: center;
    padding: 100px 16px 100px 16px;
    margin-top: 0px;
    @media screen and (max-width: 768px) {
        padding: 70px 16px 70px 16px;
    }
    h2 {
        padding-bottom: 10px;
    }
    a {
        display: block;
        width: fit-content;
        margin: 30px auto 0 auto;
        padding: 10px 60px;
        border: 1px solid black;
    }
`;

export const TeamleaderPage = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Block
                extend={{
                    backgroundColor: "#DFE6ED",
                    paddingTop: "135px",
                    textAlign: "center",
                    height: "600px",
                    marginBottom: "80px",
                    "@media (max-width: 768px)": {
                        marginBottom: "80px",
                        height: "390px"
                    }
                }}
            >
                <Text
                    as="h1"
                    variant="cook"
                    subStyle="emphasis"
                    extend={{ marginBottom: "10px" }}
                >
                    {t("teamleaderPage.heroTitle")}
                </Text>
                <Text
                    as="h4"
                    subStyle="emphasis"
                    extend={{ lineHeight: "normal" }}
                >
                    {t("teamleaderPage.heroText")}
                </Text>

                <Block
                    extend={{
                        width: "454px",
                        left: "50%",
                        transform: "translate(-50%, 80px)",
                        position: "absolute",
                        "@media (max-width: 768px)": {
                            width: "100%",
                            maxWidth: "260px",
                            transform: "translate(-50%, 50px)"
                        }
                    }}
                >
                    <img src={AppImg} alt="" style={{ width: "100%" }} />
                </Block>
            </Block>

            <Steps>
                <div className="step">
                    <div className="circle">
                        <Text as="p" subStyle="emphasis">
                            1
                        </Text>
                    </div>

                    <Text as="h2" subStyle="emphasis">
                        Skaffa enheter
                    </Text>

                    <Text variant="hillary" as="p">
                        Volvo Service är framtagen för{" "}
                        <Text subStyle="emphasis">iPhone</Text> och{" "}
                        <Text subStyle="emphasis">Apple Watch</Text> (ej
                        android). <br /> <br />
                        Appen är gratis att använda och fungerar i stort från
                        iPhone 6SE modeller med iOS 14 eller senare. Vi
                        rekommenderar dock att investera i de senaste modellerna
                        för att få bästa prestanda, funktionsutbud och
                        livslängd.
                    </Text>

                    <Block
                        extend={{
                            width: "fit-content",
                            display: "inline-block"
                        }}
                    >
                        <Link to="/valj-paket">
                            <Button intent="secondary">
                                JÄMFÖR ENHETER OCH BESTÄLL
                            </Button>
                        </Link>
                    </Block>
                </div>

                <div className="step">
                    <div className="circle">
                        <Text as="p" subStyle="emphasis">
                            2
                        </Text>
                    </div>
                    <Text as="h2" subStyle="emphasis">
                        Godkänn datadelning & integration
                    </Text>
                    <Text variant="hillary" as="p">
                        För att serviceteknikerna ska kunna se sina bokningar
                        och återkopplingar behöver ni godkänna integration samt
                        att data delas. Allt sker säkert via Service Now.
                    </Text>
                    <Block
                        extend={{
                            width: "fit-content",
                            display: "inline-block"
                        }}
                    >
                        <Link to="/godkann-datadelning">
                            <Button intent="secondary">
                                LÄS MER OCH GODKÄNN
                            </Button>
                        </Link>
                    </Block>
                </div>

                <div className="step">
                    <div className="circle">
                        <Text as="p" subStyle="emphasis">
                            3
                        </Text>
                    </div>
                    <Text as="h2" subStyle="emphasis">
                        Onboarda servicetekniker
                    </Text>
                    <Text variant="hillary" as="p">
                        För att kunna använda Volvo Service behöver vi viss
                        information om serviceteknikern. Skicka{" "}
                        <Text subStyle="emphasis">namn</Text>,{" "}
                        <Text subStyle="emphasis">email</Text>,{" "}
                        <Text subStyle="emphasis">CDSID</Text>,{" "}
                        <Text subStyle="emphasis">meknr.</Text> &{" "}
                        <Text subStyle="emphasis">anläggning</Text> till{" "}
                        <a href="mailto:volvoservice@volvocars.com">
                            volvoservice@volvocars.com
                        </a>{" "}
                        så löser vi resten.
                    </Text>
                </div>

                <div className="step">
                    <div className="circle">
                        <Text as="p" subStyle="emphasis">
                            4
                        </Text>
                    </div>
                    <Text as="h2" subStyle="emphasis">
                        Introduktion av appen
                    </Text>
                    <Text variant="hillary" as="p">
                        Nu är allting klart - för att ge er en så bra start som
                        möjligt med Volvo Service har vi en introduktion som kan
                        hållas fysiskt eller digitalt. Kontakta oss för att
                        hitta ett datum som passar er.
                    </Text>
                </div>
            </Steps>

            <Questions>
                <Text as="h2" subStyle="emphasis">
                    Hur lär man sig?
                </Text>
                <Text>
                    Appen utvecklas tillsammans med servicetekniker, och Guiden
                    visar hur appen bäst används. När nya funktioner läggs till
                    uppdaterar vi hemsidan och meddelar alla servicetekniker.
                </Text>
                <Link to="/servicetekniker">
                    <div className="button">
                        <Text subStyle="emphasis">TILL ANVÄNDARGUIDEN</Text>
                    </div>
                </Link>
            </Questions>
        </Container>
    );
};
