import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Text, View } from "vcc-ui";
import { SliderButtons } from "../../SliderButtons";
import { getRandomImages } from "../getRandomImages";
import { getRandomQuotes } from "../getRandomQuotes";
import "./styles.scss";

const Wrapper = styled.div`
    margin-bottom: 100px;
    .slide {
        img {
            width: 100%;
            max-width: 500px;
            padding: 0 20px;
            @media screen and (max-width: 900px) {
                padding: 0;
            }
        }
    }
`;

const Navigation = styled.div`
    margin: 0;
    padding: 16px;
    position: sticky;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
    text-align: left;
`;

export const TestimonialsMobileSlider = () => {
    const { t } = useTranslation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [swiper, setSwiper] = useState(null);

    const [randomQuotes, setRandomQuotes] = useState([]);
    const [randomImagesArrays, setRandomImagesArrays] = useState([]);

    useEffect(() => {
        setRandomImagesArrays(getRandomImages([3]));
        setRandomQuotes(getRandomQuotes(3));
    }, []);

    return (
        <Wrapper id="testimonial-slider">
            {randomImagesArrays?.[0]?.length > 0 && (
                <Swiper
                    onSwiper={(swiper) => setSwiper(swiper)}
                    onSlideChange={(swiperCore) => {
                        const { activeIndex } = swiperCore;
                        setCurrentSlide(activeIndex);
                    }}
                >
                    {randomImagesArrays[0].map((img) => (
                        <SwiperSlide key={img} className="slide">
                            <img src={img} alt="slide1" />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}

            <Navigation>
                {currentSlide === 0 && (
                    <View spacing={1} alignItems="flex-start">
                        <Text
                            as="blockquote"
                            subStyle="emphasis"
                            variant="hillary"
                            style={{ margin: 0, maxWidth: "none" }}
                        >
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[0]}.text`
                            )}
                        </Text>

                        <Text as="span" style={{ margin: 0, maxWidth: "none" }}>
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[0]}.name`
                            )}
                        </Text>
                    </View>
                )}

                {currentSlide === 1 && (
                    <View spacing={1} alignItems="flex-start">
                        <Text
                            as="blockquote"
                            subStyle="emphasis"
                            variant="hillary"
                            style={{ margin: 0, maxWidth: "none" }}
                        >
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[1]}.text`
                            )}
                        </Text>
                        <Text as="span" style={{ margin: 0, maxWidth: "none" }}>
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[1]}.name`
                            )}
                        </Text>
                    </View>
                )}
                {currentSlide === 2 && (
                    <View spacing={1} alignItems="flex-start">
                        <Text
                            as="blockquote"
                            subStyle="emphasis"
                            variant="hillary"
                            style={{ margin: 0, maxWidth: "none" }}
                        >
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[2]}.text`
                            )}
                        </Text>

                        <Text as="span" style={{ margin: 0, maxWidth: "none" }}>
                            {t(
                                `landingPage.slidingQuotes.${randomQuotes[2]}.name`
                            )}
                        </Text>
                    </View>
                )}

                <SliderButtons
                    currentSlide={currentSlide}
                    slidesTotal={3}
                    setCurrentSlide={setCurrentSlide}
                    swiper={swiper}
                />
            </Navigation>
        </Wrapper>
    );
};
