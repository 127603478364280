import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, SelectInput, Text } from "vcc-ui";
import { Select } from "./select";

const Container = styled.div`
    padding-bottom: 50px;
`;

const SelectContainer = styled.div`
    display: flex;
    max-width: 1100px;
    justify-content: center;
    align-items: center;
    margin: auto;
    .selectWrapper {
        width: 190px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        margin: 0 15px;
        padding-bottom: 5px;
        @media screen and (max-width: 900px) {
            &:first-of-type {
                margin-right: 20px;
            }
            &:nth-of-type(3) {
                display: none;
            }
        }
        @media screen and (max-width: 768px) {
            margin: 0;
            &:first-of-type {
                margin-right: 20px;
            }
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;
            }
        }
        select {
            border: 0;
            border-radius: 0;
            padding-top: 10px;
            cursor: pointer;
        }

        .select {
            position: relative;
            .selectOverlay {
                position: absolute;
                background: white;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                z-index: 99;
                pointer-events: none;
                h3 {
                    font-size: 24px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-bottom: 30px;
                    @media screen and (max-width: 768px) {
                        font-size: 22px;
                    }
                }
                svg {
                    position: absolute;
                    right: 0;
                    top: 11px;
                    @media screen and (max-width: 768px) {
                        width: 20px;
                        right: 5px;
                    }
                }
            }
            .selectDesc {
                position: relative;
                z-index: 100;
                padding-bottom: 8px;
                font-size: 16px;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
`;

const SelectDataContainer = styled.div`
    display: flex;
    max-width: 1100px;
    justify-content: center;
    align-items: start;
    margin: auto;
    padding-top: 30px;
`;

export const Comparison = () => {
    const { t } = useTranslation();

    const data = {
        Atea: {
            smartphone24: {
                header: {
                    category: "Atea smartphone 24",
                    title1: t("ComparisonData.Atea.smartphone24.header.title1"),
                    price1: "358 kr/mån",
                    title2: "Apple Watch SE",
                    price2: "219 kr/mån"
                },
                total: {
                    title: "KOSTNAD",
                    price: "13 848 kr",
                    priceText: "Totalt per tekniker för 24 mån (ex. moms)"
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  12",
                    text: "64 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch SE",
                    text: "32 GB"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Glasskydd",
                    text: "Monterat"
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "Apple Care for Enterprise",
                    text: "2 år"
                },
                support: {
                    title: "SUPPORT",
                    type: "Ja",
                    text: "Hjälp vid beställning, service och återtag"
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "Ja",
                    text: "Kräver MDM-system"
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "—",
                    text: ""
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "—",
                    text: ""
                }
            },
            leasing24: {
                header: {
                    category: "Atea leasing 24",
                    title1: "iPhone 12",
                    price1: "460 kr/mån",
                    title2: "Apple Watch SE",
                    price2: "167 kr/mån"
                },
                total: {
                    title: "KOSTNAD",
                    price: "15 048 kr",
                    priceText: "Totalt per tekniker för 24 mån (ex. moms)"
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  12",
                    text: "64 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch SE",
                    text: "32 GB"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Glasskydd",
                    text: "Monterat"
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "Apple Care",
                    text: "1+1 år (förlängd)"
                },
                support: {
                    title: "SUPPORT",
                    type: "Ja",
                    text: "Hjälp vid beställning, service och återtag"
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "Ja",
                    text: "Kräver MDM-system"
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "—",
                    text: ""
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "—",
                    text: ""
                }
            },
            buy: {
                header: {
                    category: "Atea köp",
                    title1: "iPhone 13",
                    price1: "9 585 kr",
                    title2: "Apple Watch 7",
                    price2: "5 142 kr"
                },
                total: {
                    title: "KOSTNAD",
                    price: "14 727 kr",
                    priceText: "Totalt per tekniker."
                },
                button: {
                    type: "Beställ",
                    text: "Atea guidar dig genom processen",
                    link: ""
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  13",
                    text: "128 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch 7",
                    text: "32 GB, 45 mm"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Glasskydd",
                    text: "Monterat"
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "Apple Care",
                    text: "1+1 år (förlängd)"
                },
                support: {
                    title: "SUPPORT",
                    type: "Ja",
                    text: "Hjälp vid beställning"
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "Ja",
                    text: "Kräver MDM-system"
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "—",
                    text: ""
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "—",
                    text: ""
                }
            }
        },
        Techstep: {
            leasing24: {
                header: {
                    category: "Techstep leasing 24",
                    title1: "Erbjuds endast som paket",
                    price1: "",
                    title2: "",
                    price2: ""
                },
                total: {
                    title: "KOSTNAD",
                    price: "489 kr/mån",
                    priceText:
                        "Leasingtid 24 mån. Totalt 11 736 kr per tekniker."
                },
                button: {
                    type: "Beställ",
                    text: "Techstep guidar dig genom processen",
                    link: ""
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  13",
                    text: "128 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch 7",
                    text: "32 GB, 45 mm"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Glasskydd",
                    text: "Monterat"
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "1+1 år",
                    text: "Förlängd garanti"
                },
                support: {
                    title: "SUPPORT",
                    type: "Ja",
                    text: "Hjälp vid beställning, service och återtag"
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "Ja",
                    text: "Kräver MDM-system"
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "Ja",
                    text:
                        "Översikt av enheter under dess livscykel för enkel kontroll."
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "Ja",
                    text:
                        "Kostnad för hantering av service & reparation av hårdvara 299 SEK per ärende. Inkluderar hanteringsavgift samt frakt. Eventuella reparationskostnader tillkommer per ärende."
                }
            },
            buy: {
                header: {
                    category: "Techstep köp",
                    title1: "Erbjuds endast som paket",
                    price1: "",
                    title2: "",
                    price2: ""
                },
                total: {
                    title: "KOSTNAD",
                    price: "12 499 kr",
                    priceText: "Totalt per tekniker."
                },
                button: {
                    type: "Beställ",
                    text: "Techstep guidar dig genom processen",
                    link: ""
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  13",
                    text: "128 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch 7",
                    text: "32 GB, 45 mm"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Glasskydd",
                    text: "Monterat"
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "1+1 år",
                    text: "Förlängd garanti"
                },
                support: {
                    title: "SUPPORT",
                    type: "Ja",
                    text: "Hjälp vid beställning, service och återtag"
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "Ja",
                    text: "Kräver MDM-system"
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "–",
                    text: ""
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "Ja",
                    text:
                        "Kostnad för hantering av service & reparation av hårdvara 299 SEK per ärende. Inkluderar hanteringsavgift samt frakt. Eventuella reparationskostnader tillkommer per ärende."
                }
            }
        },
        Mediamarkt: {
            buy: {
                header: {
                    category: "Mediamarkt köp",
                    title1: "iPhone 13",
                    price1: "7 832 kr",
                    title2: "Apple Watch 7",
                    price2: "3 912 kr"
                },
                total: {
                    title: "KOSTNAD",
                    price: "11 744 kr",
                    priceText: "Totalt per tekniker."
                },
                button: {
                    type: "",
                    text: "",
                    link: ""
                },
                mobile: {
                    title: "MOBIL",
                    type: "iPhone  13",
                    text: "128 GB"
                },
                watch: {
                    title: "KLOCKA",
                    type: "Watch 7",
                    text: "32 GB, 45 mm"
                },
                phoneCase: {
                    title: "IPHONE-SKAL",
                    type: "Silikon"
                },
                watchCase: {
                    title: "SKYDD FÖR KLOCKA",
                    type: "Nej",
                    text: ""
                },
                powerAdapter: {
                    title: "STRÖMADAPTER",
                    type: "Ja"
                },
                warranty: {
                    title: "GARANTI",
                    type: "Apple Care",
                    text: "1 år"
                },
                support: {
                    title: "SUPPORT",
                    type: "Nej",
                    text: ""
                },
                dep: {
                    title: "DEP-REGISTRERING",
                    type: "—",
                    text: " "
                },
                assetSystem: {
                    title: "ASSET-SYSTEM",
                    type: "—",
                    text: " "
                },
                repair: {
                    title: "LÄNK FÖR REPARATION",
                    type: "—",
                    text: " "
                }
            }
        }
    };

    const [select1, setSelect1] = useState({
        val: "select",
        title: "Atea",
        product: "Köp"
    });
    const [select2, setSelect2] = useState({
        val: "select",
        title: "Techstep",
        product: "Leasing 24 mån"
    });
    const [select3, setSelect3] = useState({
        val: "select",
        title: "Techstep",
        product: "Köp"
    });

    const [select4, setSelect4] = useState({
        val: "select",
        title: "Mediamarkt",
        product: "Köp (priser okt 2021)"
    });

    const [select1Data, setSelect1Data] = useState([data.Atea.buy]);
    const [select2Data, setSelect2Data] = useState([data.Techstep.leasing24]);
    const [select3Data, setSelect3Data] = useState([data.Techstep.buy]);
    const [select4Data, setSelect4Data] = useState([data.Mediamarkt.buy]);

    const [depHeight] = useState();
    const [assetHeight] = useState();
    const [totalPriceheight] = useState();
    const [warrantyHeight] = useState();
    const [supportHeight] = useState();
    const [watchHeight] = useState();
    const [orderBtnHeight] = useState();
    const [repairHeight] = useState();

    useEffect(() => {
        // A bit hacky solution.
        // Getting & setting the highest height of these columns
        // to align each header correctly.

        const depEl = document.getElementsByClassName("dep");
        const assetEl = document.getElementsByClassName("assetSystem");
        const totalPriceEl = document.getElementsByClassName("totalPrice");
        const warrantyEl = document.getElementsByClassName("warranty");
        const supportEl = document.getElementsByClassName("support");
        const watchEl = document.getElementsByClassName("watchCase");
        const buttonEl = document.getElementsByClassName("orderButton");
        const repairEl = document.getElementsByClassName("repair");

        if (repairEl && repairEl.length > 1) {
            let repairHeights = [];

            // reset heights
            for (let i = 0; i < repairEl.length; i++) {
                repairEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < repairEl.length; i++) {
                repairHeights.push(repairEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < repairEl.length; i++) {
                repairEl[i].style.height = Math.max(...repairHeights) + "px";
            }
        }

        if (buttonEl && buttonEl.length > 1) {
            let orderBtnHeights = [];

            // reset heights
            for (let i = 0; i < buttonEl.length; i++) {
                buttonEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < buttonEl.length; i++) {
                orderBtnHeights.push(buttonEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < buttonEl.length; i++) {
                buttonEl[i].style.height = Math.max(...orderBtnHeights) + "px";
            }
        }

        if (watchEl && watchEl.length > 1) {
            let watchHeights = [];

            // reset heights
            for (let i = 0; i < watchEl.length; i++) {
                watchEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < watchEl.length; i++) {
                watchHeights.push(watchEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < watchEl.length; i++) {
                watchEl[i].style.height = Math.max(...watchHeights) + "px";
            }
        }

        if (supportEl && supportEl.length > 1) {
            let supportHeights = [];

            // reset heights
            for (let i = 0; i < supportEl.length; i++) {
                supportEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < supportEl.length; i++) {
                supportHeights.push(supportEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < supportEl.length; i++) {
                supportEl[i].style.height = Math.max(...supportHeights) + "px";
            }
        }

        if (warrantyEl && warrantyEl.length > 1) {
            let warrantyHeights = [];

            // reset heights
            for (let i = 0; i < warrantyEl.length; i++) {
                warrantyEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < warrantyEl.length; i++) {
                warrantyHeights.push(warrantyEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < warrantyEl.length; i++) {
                warrantyEl[i].style.height =
                    Math.max(...warrantyHeights) + "px";
            }
        }

        if (totalPriceEl && totalPriceEl.length > 1) {
            let priceHeights = [];

            // reset heights
            for (let i = 0; i < totalPriceEl.length; i++) {
                totalPriceEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < totalPriceEl.length; i++) {
                priceHeights.push(totalPriceEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < totalPriceEl.length; i++) {
                totalPriceEl[i].style.height = Math.max(...priceHeights) + "px";
            }
        }

        if (depEl && depEl.length > 1) {
            let depHeights = [];

            // reset heights
            for (let i = 0; i < depEl.length; i++) {
                depEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < depEl.length; i++) {
                depHeights.push(depEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < depEl.length; i++) {
                depEl[i].style.height = Math.max(...depHeights) + "px";
            }
        }

        if (assetEl && assetEl.length > 1) {
            let assetHeights = [];

            // reset heights
            for (let i = 0; i < assetEl.length; i++) {
                assetEl[i].style.height = "auto";
            }
            // save all heights
            for (let i = 0; i < assetEl.length; i++) {
                assetHeights.push(assetEl[i].scrollHeight);
            }
            // set height to highest
            for (let i = 0; i < assetEl.length; i++) {
                assetEl[i].style.height = Math.max(...assetHeights) + "px";
            }
        }

        //
        const title1El = document.getElementsByClassName("title1");
        for (let i = 0; i < title1El.length; i++) {
            if (title1El[i].innerHTML.length > 15) {
                title1El[i].style.textAlign = "center";
                title1El[i].style.padding = 0;
                title1El[i].style.maxWidth = "90px";
                title1El[i].style.margin = "auto";
            } else {
                title1El[i].style.textAlign = "left";
                title1El[i].style.maxWidth = "none";
                title1El[i].style.margin = "auto";
                title1El[i].style.paddingLeft = "15px";
            }
        }
    }, [select1Data, select2Data, select3Data, select4Data]);

    const selectChange = (val, select) => {
        let title = "";
        let product = "";

        if (select === "select1") {
            if (val.includes("atea")) {
                title = "Atea";
                if (val.includes("smartphone24")) {
                    product = "Smartphone 24 mån";
                    setSelect1Data([data.Atea.smartphone24]);
                } else if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect1Data([data.Atea.leasing24]);
                } else {
                    product = "Köp";
                    setSelect1Data([data.Atea.buy]);
                }
            }
            if (val.includes("techstep")) {
                title = "Techstep";
                if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect1Data([data.Techstep.leasing24]);
                } else {
                    product = "Köp";
                    setSelect1Data([data.Techstep.buy]);
                }
            }
            if (val.includes("mediamarkt")) {
                title = "Mediamarkt";
                product = "Köp (priser okt 2021)";
                setSelect1Data([data.Mediamarkt.buy]);
            }

            setSelect1({ val: val, title: title, product: product });
        }

        if (select === "select2") {
            if (val.includes("atea")) {
                title = "Atea";
                if (val.includes("smartphone24")) {
                    product = "Smartphone 24 mån";
                    setSelect2Data([data.Atea.smartphone24]);
                } else if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect2Data([data.Atea.leasing24]);
                } else {
                    product = "Köp";
                    setSelect2Data([data.Atea.buy]);
                }
            }
            if (val.includes("techstep")) {
                title = "Techstep";
                if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect2Data([data.Techstep.leasing24]);
                } else {
                    product = "Köp";
                    setSelect2Data([data.Techstep.buy]);
                }
            }
            if (val.includes("mediamarkt")) {
                title = "Mediamarkt";
                product = "Köp (priser okt 2021)";
                setSelect2Data([data.Mediamarkt.buy]);
            }

            setSelect2({ val: val, title: title, product: product });
        }

        if (select === "select3") {
            if (val.includes("atea")) {
                title = "Atea";
                if (val.includes("smartphone24")) {
                    product = "Smartphone 24 mån";
                    setSelect3Data([data.Atea.smartphone24]);
                } else if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect3Data([data.Atea.leasing24]);
                } else {
                    product = "Köp";
                    setSelect3Data([data.Atea.buy]);
                }
            }
            if (val.includes("techstep")) {
                title = "Techstep";
                if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect3Data([data.Techstep.leasing24]);
                } else {
                    product = "Köp";
                    setSelect3Data([data.Techstep.buy]);
                }
            }
            if (val.includes("mediamarkt")) {
                title = "Mediamarkt";
                product = "Köp (priser okt 2021)";
                setSelect3Data([data.Mediamarkt.buy]);
            }

            setSelect3({ val: val, title: title, product: product });
        }

        if (select === "select4") {
            if (val.includes("atea")) {
                title = "Atea";
                if (val.includes("smartphone24")) {
                    product = "Smartphone 24 mån";
                    setSelect4Data([data.Atea.smartphone24]);
                } else if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect4Data([data.Atea.leasing24]);
                } else {
                    product = "Köp";
                    setSelect4Data([data.Atea.buy]);
                }
            }
            if (val.includes("techstep")) {
                title = "Techstep";
                if (val.includes("leasing24")) {
                    product = "Leasing 24 mån";
                    setSelect4Data([data.Techstep.leasing24]);
                } else {
                    product = "Köp";
                    setSelect4Data([data.Techstep.buy]);
                }
            }
            if (val.includes("mediamarkt")) {
                title = "Mediamarkt";
                product = "Köp (priser okt 2021)";
                setSelect4Data([data.Mediamarkt.buy]);
            }

            setSelect4({ val: val, title: title, product: product });
        }
    };

    return (
        <Container>
            <SelectContainer>
                <div className="selectWrapper">
                    <div className="select">
                        <div className="selectOverlay">
                            <Text as="h3" subStyle="emphasis">
                                {select1.title}
                            </Text>
                            <Arrow size={22} direction="down" />
                        </div>
                        <SelectInput
                            value={select1.val}
                            label=" "
                            onChange={(e) =>
                                selectChange(e.target.value, "select1")
                            }
                        >
                            <option value="select" disabled>
                                Välj
                            </option>
                            <optgroup label="Atea">
                                {/*<option value="atea_smartphone24">
                                    Smartphone 24 mån
                                </option>
                                <option value="atea_leasing24">
                                    Leasing 24 mån
                                </option>*/}
                                <option value="atea_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Techstep">
                                <option value="techstep_leasing24">
                                    Leasing 24 mån
                                </option>
                                <option value="techstep_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Mediamarkt">
                                <option value="mediamarkt_buy">
                                    Köp (priser okt 2021)
                                </option>
                            </optgroup>
                        </SelectInput>
                        <Text className="selectDesc">{select1.product}</Text>
                    </div>
                </div>

                <div className="selectWrapper">
                    <div className="select">
                        <div className="selectOverlay">
                            <Text as="h3" subStyle="emphasis">
                                {select2.title}
                            </Text>
                            <Arrow size={22} direction="down" />
                        </div>
                        <SelectInput
                            value={select2.val}
                            label=" "
                            onChange={(e) =>
                                selectChange(e.target.value, "select2")
                            }
                        >
                            <option value="select" disabled>
                                Välj
                            </option>
                            <optgroup label="Atea">
                                {/*<option value="atea_smartphone24">
                                    Smartphone 24 mån
                                </option>
                                <option value="atea_leasing24">
                                    Leasing 24 mån
                                </option>*/}
                                <option value="atea_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Techstep">
                                <option value="techstep_leasing24">
                                    Leasing 24 mån
                                </option>
                                <option value="techstep_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Mediamarkt">
                                <option value="mediamarkt_buy">
                                    Köp (priser okt 2021)
                                </option>
                            </optgroup>
                        </SelectInput>
                        <Text className="selectDesc">{select2.product}</Text>
                    </div>
                </div>

                <div className="selectWrapper">
                    <div className="select">
                        <div className="selectOverlay">
                            <Text as="h3" subStyle="emphasis">
                                {select3.title}
                            </Text>
                            <Arrow size={22} direction="down" />
                        </div>
                        <SelectInput
                            value={select3.val}
                            label=" "
                            onChange={(e) =>
                                selectChange(e.target.value, "select3")
                            }
                        >
                            <option value="select" disabled>
                                Välj
                            </option>
                            <optgroup label="Atea">
                                {/*<option value="atea_smartphone24">
                                    Smartphone 24 mån
                                </option>
                                <option value="atea_leasing24">
                                    Leasing 24 mån
                                </option>*/}
                                <option value="atea_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Techstep">
                                <option value="techstep_leasing24">
                                    Leasing 24 mån
                                </option>
                                <option value="techstep_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Mediamarkt">
                                <option value="mediamarkt_buy">
                                    Köp (priser okt 2021)
                                </option>
                            </optgroup>
                        </SelectInput>
                        <Text className="selectDesc">{select3.product}</Text>
                    </div>
                </div>

                <div className="selectWrapper">
                    <div className="select">
                        <div className="selectOverlay">
                            <Text as="h3" subStyle="emphasis">
                                {select4.title}
                            </Text>
                            <Arrow size={22} direction="down" />
                        </div>
                        <SelectInput
                            value={select4.val}
                            label=" "
                            onChange={(e) =>
                                selectChange(e.target.value, "select4")
                            }
                        >
                            <option value="select" disabled>
                                Välj
                            </option>
                            <optgroup label="Atea">
                                {/*<option value="atea_smartphone24">
                                    Smartphone 24 mån
                                </option>
                                <option value="atea_leasing24">
                                    Leasing 24 mån
                                </option>*/}
                                <option value="atea_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Techstep">
                                <option value="techstep_leasing24">
                                    Leasing 24 mån
                                </option>
                                <option value="techstep_buy">Köp</option>
                            </optgroup>

                            <optgroup label="Mediamarkt">
                                <option value="mediamarkt_buy">
                                    Köp (priser okt 2021)
                                </option>
                            </optgroup>
                        </SelectInput>
                        <Text className="selectDesc">{select4.product}</Text>
                    </div>
                </div>
            </SelectContainer>

            <SelectDataContainer>
                <Select
                    data={select1Data[0]}
                    totalPriceheight={totalPriceheight}
                    supportHeight={supportHeight}
                    warrantyHeight={warrantyHeight}
                    depHeight={depHeight}
                    assetHeight={assetHeight}
                    watchHeight={watchHeight}
                    orderBtnHeight={orderBtnHeight}
                    repairHeight={repairHeight}
                />
                <Select
                    data={select2Data[0]}
                    totalPriceheight={totalPriceheight}
                    supportHeight={supportHeight}
                    warrantyHeight={warrantyHeight}
                    depHeight={depHeight}
                    assetHeight={assetHeight}
                    watchHeight={watchHeight}
                    orderBtnHeight={orderBtnHeight}
                    repairHeight={repairHeight}
                />
                <Select
                    data={select3Data[0]}
                    totalPriceheight={totalPriceheight}
                    supportHeight={supportHeight}
                    warrantyHeight={warrantyHeight}
                    depHeight={depHeight}
                    assetHeight={assetHeight}
                    watchHeight={watchHeight}
                    orderBtnHeight={orderBtnHeight}
                    repairHeight={repairHeight}
                />
                <Select
                    data={select4Data[0]}
                    totalPriceheight={totalPriceheight}
                    supportHeight={supportHeight}
                    warrantyHeight={warrantyHeight}
                    depHeight={depHeight}
                    assetHeight={assetHeight}
                    watchHeight={watchHeight}
                    orderBtnHeight={orderBtnHeight}
                    repairHeight={repairHeight}
                />
            </SelectDataContainer>
        </Container>
    );
};
