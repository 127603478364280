import ClientOAuth2 from "client-oauth2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Text } from "vcc-ui";
import "./styles.scss";

const pstClientId = "1918daaa-d653-4a61-b4c2-a4dbc0dab65e";
// const selektChecklistsId = "fde6d343-0a61-439b-aee2-1ce763c9957e"; // I used this one during testing since it works
const useClientId = pstClientId;

const volvoAuth = new ClientOAuth2({
    clientId: useClientId,
    accessTokenUri:
        "https://login.microsoftonline.com/81fa766e-a349-4867-8bf4-ab35e250a08f/oauth2/v2.0/token",
    authorizationUri:
        "https://login.microsoftonline.com/81fa766e-a349-4867-8bf4-ab35e250a08f/oauth2/v2.0/authorize",
    redirectUri: window.location.origin + "/login", // You MUST redirect back to the login page!
    scopes: [useClientId + "/.default"]
    // scopes: ['User.read openid email'] // Combinations of these things didn't work
});

let previousHash = null;

const Login = ({ setLoginToken, clearLoginToken, loggedInUser }) => {
    // const history = useHistory();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);

    const doLogin = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Store this first
        window.sessionStorage.setItem("redirectUrl", window.location.pathname);

        // Redirect window to Volvo Login endpoint
        const endpoint = volvoAuth.token.getUri();
        window.location.href = endpoint;
    };

    const doDummyLogin = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const accessToken = "1234";
        const tokenExpiry = new Date();
        tokenExpiry.setSeconds(tokenExpiry.getSeconds() + 30 * 60);
        const userObj = {
            loggedIn: true,
            dummyLogin: true
        };
        setLoginToken(accessToken, tokenExpiry, userObj);
    };

    useEffect(() => {
        // console.log("History or set login token has changed");
        const setAccessTokenUserAndRedirect = (token, tokenExpiry, user) => {
            setLoginToken(token, tokenExpiry, user);

            // If there is a redirectUrl, push to here
            const redirectUrl = window.sessionStorage.getItem("redirectUrl");
            if (redirectUrl) {
                window.sessionStorage.removeItem("redirectUrl");
                navigate(redirectUrl);
                // history.push(redirectUrl);
            }
        };

        const hashHasChanged = async (e) => {
            // Does not seem to stop firing
            if (previousHash !== null) {
                if (previousHash === window.location.hash) {
                    // It's the same hash
                    return;
                }
            } else {
                previousHash = window.location.hash;
            }

            // console.log("Hash has changed to: " + window.location.hash);
            if (window.location.hash && window.location.hash.length > 10) {
                // There is a hash, try to decode it now
                try {
                    const token = await volvoAuth.token.getToken(
                        window.location
                    );

                    const accessToken = token.accessToken;
                    const tokenExpiry = token.expires;
                    const userObj = {
                        loggedIn: true
                    };

                    /*
                    console.log("accessToken", accessToken);
                    console.log("tokenExpiry", tokenExpiry);
                    console.log("user", userObj);
                    */

                    /*
                    const result = await axios({
                        url: "/loginToken",
                        method: "POST",
                        data: {
                            token: accessToken,
                        },
                        validateStatus: () => true,
                    });
                    if (result.status === 200) {
                        setAccessTokenUserAndRedirect(result.data.token, result.data.tokenExpiry, result.data.user);
                    } else {
                        throw new Error("Non 200 response: " + result.status);
                    }
                    */

                    setAccessTokenUserAndRedirect(
                        accessToken,
                        tokenExpiry,
                        userObj
                    );
                } catch (e) {
                    console.error(e);
                    setErrorMessage(e.message);
                    // alert(e.message);
                }
            }
        };

        window.addEventListener("hashchange", hashHasChanged);
        hashHasChanged();
        return () => {
            window.removeEventListener("hashchange", hashHasChanged);
        };
    }, [navigate, setLoginToken]);

    const liveHostnames = [
        "serviceapp.volvocars.biz",
        "pst-info-site.gvcs.fbinhouse.se"
    ];
    const isLive = liveHostnames.includes(window.location.hostname);

    return (
        <div className="loginForm">
            {loggedInUser ? (
                <div className="userDetails">
                    <Text>
                        Inloggad som {loggedInUser.firstName}{" "}
                        {loggedInUser.lastName}
                    </Text>

                    <Button
                        onClick={() => clearLoginToken()}
                        intent="secondary"
                    >
                        Logout
                    </Button>
                </div>
            ) : (
                <>
                    {errorMessage && (
                        <div className="loginError">
                            <Text>Error: {errorMessage}</Text>
                        </div>
                    )}

                    {/* <Text>You need to login to view this website</Text> */}
                    <Button onClick={(e) => doLogin(e)}>
                        Logga in med Volvo CDSID
                    </Button>

                    {!isLive && (
                        <div className="testing">
                            <Text>
                                This is not a LIVE production url, so you <br />
                                can use this button to login for 30 minutes
                            </Text>
                            <Button onClick={(e) => doDummyLogin(e)}>
                                Dummy login
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export { Login };
