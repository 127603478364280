import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block, Spacer, Text, View } from "vcc-ui";
import { useElementScrollPos } from "../../../hooks/useElementScrollPos";
import "../variables.scss";
import "./styles.scss";

export function ThreeAnimatedTaglines({
    children
}: {
    children: React.ReactNode;
}) {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    const percent = useElementScrollPos(ref);

    const [currentClassName, setCurrentClassName] = useState<
        "" | "one" | "two" | "three" | "out"
    >("");

    useEffect(() => {
        if (percent < 0.1) {
            setCurrentClassName("");
        }
        if (percent >= 0.1 && percent < 0.4) {
            setCurrentClassName("one");
        }
        if (percent >= 0.4 && percent < 0.7) {
            setCurrentClassName("two");
        }
        if (percent >= 0.7 && percent < 1) {
            setCurrentClassName("three");
        }
        if (percent >= 1) {
            setCurrentClassName("out");
        }
    }, [percent]);

    return (
        <View>
            <View ref={ref}>
                <View spacing={1} extend={{ position: "sticky", top: "20vh" }}>
                    <View
                        className={
                            "animation-wrapper-variables hero-animation-wrapper " +
                            currentClassName
                        }
                        extend={{ textAlign: "center" }}
                    >
                        <Text
                            as="span"
                            variant="peary"
                            extend={{
                                fontSize: "64px !important",
                                "@media (max-width: 768px)": {
                                    fontSize: "35px !important"
                                }
                            }}
                        >
                            {t("landingPage.ingressTagline1")}
                        </Text>
                        <Text
                            as="span"
                            variant="peary"
                            extend={{
                                fontSize: "64px !important",
                                "@media (max-width: 768px)": {
                                    fontSize: "35px !important"
                                }
                            }}
                        >
                            {t("landingPage.ingressTagline2")}
                        </Text>
                        <Text
                            as="span"
                            variant="peary"
                            extend={{
                                fontSize: "64px !important",
                                "@media (max-width: 768px)": {
                                    fontSize: "44px !important"
                                }
                            }}
                        >
                            {t("landingPage.ingressTagline3")}
                        </Text>
                    </View>
                    <Spacer size={32} />
                    {children}
                </View>
                <Block extend={{ minHeight: "200vh" }} />
            </View>
        </View>
    );
}
