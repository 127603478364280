import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TextInput, TextArea, Button } from "vcc-ui";
import styled from "@emotion/styled";
import { Hero } from "../../components/Hero";
import { ContactConfirm } from "../../components/ContactConfirm";
import { SEND_CONTACT } from "../../graphql/mutations/sendContact";
import { useMutation } from "@apollo/client";

const Container = styled.div`
    padding: 20px;
    padding-bottom: 90px;

    .ingress {
        text-align: center;
        font-size: 24px;
        padding-bottom: 90px;
        @media screen and (min-width: 769px) {
            margin-top: -40px;
            padding-bottom: 80px;
        }
    }

    form {
        max-width: 500px;
        margin: auto;
        .inputWrapper {
            margin-bottom: 35px;
            button {
                margin-top: 15px;
            }
            p {
                font-size: 20px;
                margin-bottom: 6px;
            }
            textarea {
                min-height: 120px;
            }
        }

        .feedback {
            text-align: center;
            max-width: 280px;
            margin: auto;
            margin-top: 14px;
        }
    }
`;

export const Contact = () => {

    const { t } = useTranslation();

    const [createContact] = useMutation(SEND_CONTACT, {
        onCompleted() {
            setSent(true);

            setName("");
            setEmail("");
            setPhone("");
            setMatter("");
            setRole("");
            setCompany("");
            setMessage("");
        },
        onError(error) {
            console.log(error);
            setSendError(true);
        }
    });

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [matter, setMatter] = useState("");
    const [role, setRole] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const [formValid, setFormValid] = useState(true);
    const [formSent, setSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    const validateEmail = (email) => {
        const rule = RegExp(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        );
        return rule.test(email);
    };

    const validateForm = () => {
        if (
            name.length > 1 &&
            validateEmail(email) &&
            phone.length > 1 &&
            matter.length > 1 &&
            role.length > 1 &&
            company.length > 1 &&
            message.length > 1
        ) {
            setFormValid(true);
            sendForm();
        } else {
            setFormValid(false);
        }
    };

    const sendForm = () => {
        createContact({
            variables: {
                input: {
                    subject: matter,
                    name: name,
                    email: email,
                    phone: phone,
                    role: role,
                    dealer: company,
                    message: message
                }
            }
        });
    };


    return (
        <Container>
            {!formSent && (
                <>
                    <Hero title={t("ContactForm.header")} marginBottom="91px" />

                    <Text subStyle="emphasis" as="p" className="ingress">
                        {t("ContactForm.text")}
                    </Text>

                    <form>
                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.matter")}
                            </Text>
                            <TextInput
                                name="matter"
                                label={t("ContactForm.type")}
                                onChange={(e) => setMatter(e.target.value)}
                                value={matter}
                                isValid={
                                    !formValid && matter.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.name")}
                            </Text>
                            <TextInput
                                name="name"
                                label={t("ContactForm.type")}
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                isValid={
                                    !formValid && name.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.mail")}
                            </Text>
                            <TextInput
                                name="email"
                                label={t("ContactForm.type")}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                isValid={
                                    !formValid && !validateEmail(email)
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.phone")}
                            </Text>
                            <TextInput
                                name="phone"
                                label={t("ContactForm.type")}
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                isValid={
                                    !formValid && phone.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.role")}
                            </Text>
                            <TextInput
                                name="role"
                                label={t("ContactForm.type")}
                                onChange={(e) => setRole(e.target.value)}
                                value={role}
                                isValid={
                                    !formValid && role.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.company")}
                            </Text>
                            <TextInput
                                name="company"
                                label={t("ContactForm.type")}
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                isValid={
                                    !formValid && company.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("ContactForm.message")}
                            </Text>
                            <TextArea
                                name="message"
                                label={t("ContactForm.type")}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                isValid={
                                    !formValid && company.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <Button
                            intent="secondary"
                            onClick={() => validateForm()}
                        >
                            {t("ContactForm.button")}
                        </Button>

                        {sendError && (
                            <Text className="feedback">
                                {t("FeedbackForm.error")}
                            </Text>
                        )}
                    </form>
                </>
            )}

            {formSent && <ContactConfirm />}
        </Container>
    );
};
