export function getRandomQuotes(numberOfQuotes: number): number[] {
    const result: number[] = [];
    const quotes = [1, 2, 3, 4, 5];

    for (let i = 0; i < Math.min(numberOfQuotes, quotes.length); i++) {
        if (quotes.length < 1) break;
        const randomIndex = Math.floor(Math.random() * quotes.length);
        const quote = quotes.splice(randomIndex, 1);
        if (quote.length > 0) result.push(quote[0]);
    }

    return result.filter((quote) => Number.isInteger(quote));
}
