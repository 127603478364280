import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Hero } from "../../components/Hero";
import { LoadingPage } from "../../components/LoadingPage";
import { Text } from "vcc-ui";
import { useQuery } from "@apollo/client";
import { GET_RELEASE } from "../../graphql/queries";
import * as Showdown from "showdown";

const Container = styled.div`
    margin: 16px;
    padding-bottom: 70px;

    .versions {
        > div {
            &:last-of-type {
                padding-bottom: 0;
            }
        }
        @media screen and (min-width: 769px) {
            margin-top: -20px;
        }
    }
`;

const Version = styled.div`
    padding-bottom: 30px;
    @media screen and (min-width: 769px) {
        text-align: center;
    }
    .description {
        h4,
        h5 {
            font-weight: normal;
            margin: 0 0 8px 0;
        }
        p {
            margin-top: 0;
        }
    }
    
    h3 {
        font-size: 24px;
    }
    p {
        font-size: 20px;
        margin-top: 10px;
    }
`;

export const ReleaseNotes = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    const { data, loading, error } = useQuery(GET_RELEASE, {
        variables: { language: currentLang }
    });

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    if (loading) {
        return <LoadingPage />;
    }

    if (error || !data) {
        console.log(error);
        return <Text>Failed to fetch data</Text>;
    }

    return (
        <Container>
            <Hero
                title="Release notes."
                marginBottom="100px"
                headerWidth="400px"
            />
            <div className="versions">
                {data.pst.release.map((val, i) => (
                    <Version key={i}>
                        <Text subStyle="emphasis" as="h3">
                            {val.title}
                        </Text>
                        <Text as="p" className="description">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(val.description)
                                }}
                            />
                        </Text>
                    </Version>
                ))}
            </div>
        </Container>
    );
};
