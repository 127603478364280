import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Button } from "vcc-ui";
import styled from "@emotion/styled";
import Check from "../../assets/check.gif";

const Container = styled.div`
    margin-top: 70px;
    padding: 0 20px;
    text-align: center;

    .confirmed {
        h1 {
            font-size: 36px;
            margin-top: 25px;
            @media screen and (max-width: 768px),
                screen and (max-height: 900px) {
                margin-top: 15px;
            }
        }
        p {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            &:last-of-type {
                font-size: 16px;
                color: var(--gray2);
                @media screen and (max-width: 768px),
                    screen and (max-height: 900px) {
                    margin-top: 15px;
                }
            }
        }

        .check {
            width: 100%;
            max-width: 41px;
        }
    }
`;

const NextStep = styled.div`
    h2 {
        font-size: 28px;
        margin-top: 70px;
        margin-bottom: 20px;
        @media screen and (max-width: 768px), screen and (max-height: 900px) {
            margin-top: 50px;
            margin-bottom: 15px;
        }
    }
    p {
        font-size: 20px;
    }
    .buttonWrapper {
        p {
            color: var(--gray3);
            font-size: 14px;
            text-align: center;
        }
        button,
        a {
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
`;

export const OrderConfirm = ({ email }) => {

    const { t } = useTranslation();

    window.scrollTo(0, 0);

    return (
        <Container>
            <div className="confirmed">
                <img src={Check} alt="check" className="check" />
                <Text as="h1" subStyle="emphasis">
                    {t("OrderConfirm.header")}
                </Text>
                <Text as="p" subStyle="emphasis">
                    {t("OrderConfirm.subHeader")}
                </Text>
                {/*<Text>
                    {t("OrderConfirm.confirmEmail")}&nbsp;
                    {email ? email : t("OrderConfirm.fallback")}
                </Text>*/}
            </div>

            <NextStep>
                <Text as="h2" subStyle="emphasis">
                    {t("OrderConfirm.header2")}
                </Text>
                <Text as="p" subStyle="emphasis">
                    {t("OrderConfirm.subHeader2")}
                </Text>
                <div className="buttonWrapper">
                    <Button intent="secondary" href="/godkann-datadelning">
                        {t("OrderConfirm.button")}
                    </Button>
                </div>
            </NextStep>
        </Container>
    );
};
