import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Block, Text } from "vcc-ui";
// import { LanguageSwitch } from "../../components/LanguageSwitch";

const Container = styled.div`
    background-color: #ffffff;
    padding: 15px 20px;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    /* box-sizing: border-box; */
    overflow: hidden;
    z-index: 999;
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
            cursor: pointer;
            margin-left: 40px;
            .line {
                width: 30px;
                border-bottom: 2px solid #000000;
                &:first-of-type {
                    margin-bottom: 11px;
                }
            }
        }
    }
`;

const MenuList = styled.div`
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 900;
    height: 0;
    overflow: hidden;
    transition: 0.4s ease height;
    &.active {
        height: 100%;
        @media screen and (max-width: 768px) {
            overflow: auto;
        }
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 32px;
        height: 28px;
        z-index: 999;
        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: " ";
            height: 26px;
            width: 2px;
            background-color: #fff;
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:before {
            transform: rotate(45deg);
        }
    }

    .menuOuter {
        @media screen and (min-width: 768px) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .menuGroup {
        z-index: 100;
        color: #fff;
        padding-top: 40px;
        &:last-of-type {
            padding-bottom: 40px;
        }
        .navlink {
            color: #fff;
            font-size: 20px;
            margin-bottom: 10px;
        }
        .navheader {
            color: #fff;
            margin-bottom: 20px;
            text-align: center;
        }
        a {
            width: max-content;
            display: block;
            margin: auto;
        }
    }
`;

export const Menu = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [path, setPath] = useState("");

    useEffect(() => {
        setPath(window.location.pathname);
    }, [location]);

    return (
        <Container>
            <div className="wrapper">
                <Link to="/">
                    <Text as="p" variant="hillary" subStyle="emphasis">
                        Volvo Service
                    </Text>
                </Link>

                <Block extend={{ display: "flex", alignItems: "center" }}>
                    <Block
                        extend={{
                            display: "flex",
                            marginRight: "0",
                            //marginRight: "38px",
                            "@media (max-width: 768px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link to="/varfor-volvo-service">
                            <Text
                                as="p"
                                variant="columbus"
                                subStyle={
                                    path === "/varfor-volvo-service"
                                        ? "emphasis"
                                        : "default"
                                }
                            >
                                Varför Volvo Service
                            </Text>
                        </Link>

                        <Link to="/inkopsansvar" style={{ margin: "0 38px" }}>
                            <Text
                                as="p"
                                variant="columbus"
                                subStyle={
                                    path === "/inkopsansvar"
                                        ? "emphasis"
                                        : "default"
                                }
                            >
                                Skaffa appen
                            </Text>
                        </Link>

                        <Link to="/servicetekniker">
                            <Text
                                as="p"
                                variant="columbus"
                                subStyle={
                                    path === "/servicetekniker"
                                        ? "emphasis"
                                        : "default"
                                }
                            >
                                Hjälp med appen
                            </Text>
                        </Link>
                    </Block>

                    {/*<LanguageSwitch />*/}

                    <div className="icon" onClick={() => setOpen(true)}>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </Block>
            </div>

            <MenuList className={open ? "active" : ""}>
                <div className="close" onClick={() => setOpen(false)}></div>
                <div className="menuOuter">
                    <div className="menuGroup">
                        <Text subStyle="emphasis" className="navheader">
                            VOLVO SERVICE
                        </Text>
                        <Link
                            to="/varfor-volvo-service"
                            onClick={() => setOpen(false)}
                        >
                            <Text className="navlink">
                                {t("Menu.whyVolvoService")}
                            </Text>
                        </Link>
                        <Link to="/inkopsansvar" onClick={() => setOpen(false)}>
                            <Text className="navlink">{t("Menu.getApp")}</Text>
                        </Link>
                        <Link to="/valj-paket" onClick={() => setOpen(false)}>
                            <Text className="navlink">{t("Menu.order")}</Text>
                        </Link>
                    </div>

                    <div className="menuGroup">
                        <Text subStyle="emphasis" className="navheader">
                            {t("Menu.helpHeader")}
                        </Text>
                        <Link
                            to="/servicetekniker"
                            onClick={() => setOpen(false)}
                        >
                            <Text className="navlink">{t("Menu.guide")}</Text>
                        </Link>
                        <Link to="/faq" onClick={() => setOpen(false)}>
                            <Text className="navlink">{t("Menu.faq")}</Text>
                        </Link>
                        <Link to="/kontakta" onClick={() => setOpen(false)}>
                            <Text className="navlink">
                                {t("Menu.techSupport")}
                            </Text>
                        </Link>
                    </div>

                    <div className="menuGroup">
                        <Text subStyle="emphasis" className="navheader">
                            {t("Menu.feedbackHeader")}
                        </Text>
                        <Link
                            to="/skicka-feedback"
                            onClick={() => setOpen(false)}
                        >
                            <Text className="navlink">
                                {t("Menu.sendFeedback")}
                            </Text>
                        </Link>
                        <Link to="/releases" onClick={() => setOpen(false)}>
                            <Text className="navlink">
                                {t("Menu.releaseNotes")}
                            </Text>
                        </Link>
                        <Link to="/kontakta" onClick={() => setOpen(false)}>
                            <Text className="navlink">{t("Menu.contact")}</Text>
                        </Link>
                    </div>
                </div>
            </MenuList>
        </Container>
    );
};
