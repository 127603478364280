import styled from "@emotion/styled";
import React from "react";
import { Spinner } from "vcc-ui";

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoadingPage = () => {
    return (
        <Container>
            <Spinner size={48} />
        </Container>
    );
};
