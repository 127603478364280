import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Icon, Text } from "vcc-ui";
import { tableOfContentsWrangler } from "../../helpers/tableOfContentsWrangler";

const BarContainer = styled.nav`
    width: 100%;
    transform: translateY(${(props) => (props.visible ? "0%" : "-100%")});
    position: fixed;
    top: ${(props) => props.top};
    transition: transform 200ms ease-in-out, height 200ms ease-in-out;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* * {
        box-sizing: border-box;
    } */

    z-index: 888;
`;

const Menu = styled.div`
    width: 100%;
    max-width: 960px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const Content = styled.div`
    width: 100%;
    max-width: 960px;
    height: fit-content;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 760px) {
        max-height: 100vh;
        overflow-y: auto;
    }

    & > * {
        width: 50%;
    }
`;

const Category = styled.div`
    margin-bottom: 0.5rem;
`;

export const TableOfContentsBar = ({
    guide,
    scrollTo,
    visible,
    top,
    title
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!visible) setOpen(false);
    }, [visible, open]);

    const categories = tableOfContentsWrangler(guide);

    return (
        <BarContainer visible={visible} top={top}>
            <Menu onClick={() => setOpen(!open)}>
                <Text extend={{ margin: "0 !important" }}>{title}</Text>
                {open ? (
                    <Icon type="navigation-chevronup-24" />
                ) : (
                    <Icon type="navigation-chevrondown-24" />
                )}
            </Menu>

            {open && (
                <Content>
                    {categories.map((category) => (
                        <Category key={category.title}>
                            <Text
                                variant="bates"
                                extend={{ textTransform: "uppercase" }}
                            >
                                {category.title}
                            </Text>
                            <div>
                                {category.entries.map((entry, idx) => (
                                    <Text
                                        key={`ToC-bar-entry-${entry.title}`}
                                        subStyle="inline-link"
                                        onClick={() => {
                                            setOpen(false);
                                            scrollTo(entry.title);
                                        }}
                                        extend={{ cursor: "pointer" }}
                                    >
                                        {entry.title}
                                    </Text>
                                ))}
                            </div>
                        </Category>
                    ))}
                </Content>
            )}
        </BarContainer>
    );
};
