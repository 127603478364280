import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Showdown from "showdown";
import { Text } from "vcc-ui";
import { Hero } from "../../components/Hero";
import { LoadingPage } from "../../components/LoadingPage";
import {
    TableOfContentsBar,
    TableOfContentsSection
} from "../../components/TableOfContents";
import { GET_PINGPILOT } from "../../graphql/queries";

const Container = styled.div``;

const ImageBlock = styled.div`
    padding: 0 16px 80px 16px;
    text-align: center;
    @media screen and (max-width: 768px) {
        padding: 0 16px 60px 16px;
    }
    &:last-of-type {
        padding-bottom: 95px;
    }
    p {
        display: block;
        text-align: center;
        text-align: left;
        @media screen and (min-width: 769px) {
            max-width: 900px !important;
        }
    }
    .description {
        h4,
        h5 {
            font-weight: normal;
            margin: 0 0 8px 0;
        }
        p {
            margin-top: 0;
        }
    }

    img {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: auto;
        margin-left: auto;
        width: 960px;
        height: auto;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
`;

const Content = styled.div`
    text-align: center;
    margin-bottom: 119px;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    background: #dfe6ed;
    background: #dfe6ed;
    padding: 40px 0;
    @media screen and (max-width: 768px) {
        margin-bottom: 64px;
    }
    h4 {
        margin-bottom: 25px;
        margin-left: auto;
        margin-right: auto;
        display: inline-flex;
        cursor: pointer;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

const VimeoWrapper = styled.div`
    width: 100%;
    //height: 540px;
    height: 830px;
    overflow: hidden;
    top: 0;
    right: 0;
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
        //padding-bottom: 56.25%; // 9/16
        padding-bottom: 100%;
        height: 0;
        overflow: hidden;
        border-left: none;
        border-right: none;
        margin: 0 auto;
        margin-top: 20px;
    }
    iframe {
        @media screen and (max-width: 1024px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
`;

export const PingPilot = () => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const [tocVisible, setTocVisible] = useState(true);

    const tableOfContentsRef = React.createRef(null);

    const { data, loading, error } = useQuery(GET_PINGPILOT, {
        variables: { language: currentLang }
    });

    const refs = [];

    const scrollToElement = (id) => {
        const offset = -120;
        const target = document.getElementById(id).offsetTop + offset;

        window.scrollTo({ top: target, behavior: "smooth" });
    };

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    const intersectionCallback = (entries) => {
        const [entry] = entries;
        setTocVisible(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCallback);
        const ref = tableOfContentsRef;

        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [tableOfContentsRef]);

    if (loading) {
        return <LoadingPage />;
    }

    if (error || !data) {
        console.log(error);
        return <Text>Failed to fetch data</Text>;
    }

    return (
        <Container>
            <Hero
                title={t("pingPilot.heroTitle")}
                text={t("pingPilot.heroText")}
                headerWidth={"100%"}
            />
            <Content ref={tableOfContentsRef}>
                <Text
                    extend={{
                        fontSize: "13px !important",
                        color: "var(--gray3)",
                        marginBottom: "28px"
                    }}
                    subStyle="emphasis"
                >
                    {t("pingPilot.content")}
                </Text>

                <TableOfContentsSection
                    guide={data.pst.pingpilot}
                    scrollTo={scrollToElement}
                />
            </Content>
            <TableOfContentsBar
                title="Ping Pilot"
                guide={data.pst.pingpilot}
                scrollTo={scrollToElement}
                visible={!tocVisible}
                top="56px"
            />

            {data.pst.pingpilot.map((val, i) => {
                const newRef = React.createRef();
                refs.push(newRef);

                return (
                    <ImageBlock key={i}>
                        <Text
                            id={val.title}
                            as="h3"
                            subStyle="emphasis"
                            ref={newRef}
                            style={{ marginBottom: "16px" }}
                        >
                            {val.title}
                        </Text>
                        <Text variant="columbus" as="p" className="description">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(val.description)
                                }}
                            />
                        </Text>
                        {val.video && (
                            <VimeoWrapper>
                                <iframe
                                    title="video"
                                    src={
                                        val.video +
                                        "?&title=0&byline=0&portrait=0"
                                    }
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                ></iframe>
                            </VimeoWrapper>
                        )}
                        {val.image && (
                            <div>
                                <img src={val.image} alt="" />
                            </div>
                        )}
                    </ImageBlock>
                );
            })}
        </Container>
    );
};
