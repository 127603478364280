import React from "react";
import { Block, Text, View } from "vcc-ui";
import leftBtn from "../../assets/left.svg";
import rightBtn from "../../assets/right.svg";

export function SliderButtons({
    currentSlide,
    slidesTotal,
    setCurrentSlide,
    swiper
}) {
    return (
        <View
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="flex-end"
            extend={{ marginTop: "64px" }}
        >
            <View direction="row" spacing={2}>
                <img
                    style={{ width: "50px" }}
                    src={leftBtn}
                    alt="prev"
                    onClick={() => {
                        currentSlide > 0 && setCurrentSlide(currentSlide - 1);
                        swiper.slideTo(currentSlide - 1);
                    }}
                ></img>
                <img
                    style={{ width: "50px" }}
                    src={rightBtn}
                    alt="next"
                    onClick={() => {
                        currentSlide < slidesTotal - 1 &&
                            setCurrentSlide(currentSlide + 1);
                        swiper.slideTo(currentSlide + 1);
                    }}
                ></img>
            </View>

            <Block>
                <Text>{currentSlide + 1 + "/" + slidesTotal}</Text>
            </Block>
        </View>
    );
}
