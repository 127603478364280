import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TextInput, Button, SelectInput } from "vcc-ui";
import styled from "@emotion/styled";
import { Hero } from "../../components/Hero";
import { OrderConfirm } from "../../components/OrderConfirm";
import Logo from "../../assets/techstep.png";

const Container = styled.div`
    padding: 20px;
    padding-bottom: 90px;

    .ingress {
        text-align: center;
        font-size: 24px;
        padding-bottom: 90px;
    }

    h1 {
        font-size: 36px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        margin-top: -35px;
    }

    form {
        max-width: 500px;
        margin: auto;
        .inputWrapper {
            margin-bottom: 35px;
            button {
                margin-top: 15px;
            }
            p {
                font-size: 20px;
                margin-bottom: 6px;
            }
        }

        .feedback {
            text-align: center;
            max-width: 280px;
            margin: auto;
            margin-top: 14px;
        }
    }
`;

export const TechstepForm = () => {

    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("select");

    const [formValid, setFormValid] = useState(true);
    const [formSent, setSent] = useState(false);

    const validateEmail = (email) => {
        const rule = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
        return rule.test(email);
    };

    const validateForm = () => {
        if (
            name.length > 1 &&
            validateEmail(email) &&
            phone.length > 1 &&
            company !== "select"
        ) {
            console.log("valid");
            setFormValid(true);
            sendForm();
        } else {
            console.log("not valid");
            setFormValid(false);
        }
    };

    const sendForm = () => {
        console.log("send form");
        setSent(true);
    };

    return (
        <Container>
            {!formSent && (
                <div className="techstepForm">
                    <Hero logo={Logo} logoWidth="205px" />

                    <Text as="h1" subStyle="emphasis">
                        {t("TechstepForm.header")}
                    </Text>

                    <Text subStyle="emphasis" as="p" className="ingress">
                        {t("TechstepForm.text")}
                    </Text>

                    <form>
                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("TechstepForm.name")}
                            </Text>
                            <TextInput
                                name="name"
                                label={t("TechstepForm.type")}
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                isValid={
                                    !formValid && name.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("TechstepForm.company")}
                            </Text>
                            <SelectInput
                                value={company}
                                label=""
                                onChange={(e) => setCompany(e.target.value)}
                                isValid={
                                    !formValid && company === "select"
                                        ? false
                                        : true
                                }
                            >
                                <option disabled value="select">
                                    {t("TechstepForm.select")}
                                </option>
                                <option value="opt1">Option 1</option>
                                <option value="opt2">Option 2</option>
                                <option value="opt3">Option 3</option>
                                <option value="opt4">Option 4</option>
                                <option value="opt5">Option 5</option>
                                <option value="opt6">Option 6</option>
                            </SelectInput>
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("TechstepForm.mail")}
                            </Text>
                            <TextInput
                                name="email"
                                label={t("TechstepForm.type")}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                isValid={
                                    !formValid && !validateEmail(email)
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("TechstepForm.phone")}
                            </Text>
                            <TextInput
                                name="phone"
                                label={t("TechstepForm.type")}
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                isValid={
                                    !formValid && phone.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <Button
                            intent="secondary"
                            onClick={() => validateForm()}
                        >
                            {t("TechstepForm.button")}
                        </Button>

                    </form>
                </div>
            )}

            {formSent && <OrderConfirm email={email} />}
        </Container>
    );
};
