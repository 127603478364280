const getEndpoint = () => {
    const hostname = window.location.hostname;
    let url = "https://pst.services-stage.chaz.fbinhouse.se/graphql";

    if (hostname.includes("test") || hostname.includes("localhost")) {
        url = "https://pst.services-test.chaz.fbinhouse.se/graphql";
    } else if (hostname.includes("stage")) {
        url = "https://pst.services-stage.chaz.fbinhouse.se/graphql";
    } else {
        url = "https://pst.services.chaz.fbinhouse.se/graphql";
    }

    return url;
};

const endpointToUse = getEndpoint();

export const endpoint = endpointToUse;
