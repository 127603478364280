import { ApolloProvider } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
    BrowserRouter,
    Route,
    Routes as ReactRouterDomRoutes,
    useLocation
} from "react-router-dom";
import { StyleProvider, ThemePicker } from "vcc-ui";
import { Footer } from "../components/Footer";
import { Login } from "../components/Login";
import { Menu } from "../components/Menu";
import { client } from "../graphql/client";
import { ApproveDataSharing } from "./ApproveDataSharing";
import { AteaForm } from "./AteaForm";
import { ChoosePackage } from "./ChoosePackage";
import { Contact } from "./Contact";
import { FAQ } from "./FAQ";
import { Feedback } from "./Feedback";
import { LandingPage } from "./LandingPage";
import { PingPilot } from "./PingPilot";
import { ReleaseNotes } from "./ReleaseNotes";
import { TeamleaderPage } from "./TeamleaderPage";
import { TechnicianPage } from "./TechnicianPage";
import { TechstepForm } from "./TechstepForm";
import { WhyVolvoService } from "./WhyVolvoService";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scroll(0, 0);
        }, 100);
    }, [pathname]);

    return null;
}

export const Routes = () => {
    // Note: The access token itself is stored in sessionStorage
    const [loggedInUser, setLoggedInUser] = useState(null);

    const setLoginTokenAndUser = (token, tokenExpiry, user) => {
        // First save in session storage
        window.sessionStorage.setItem(
            "token",
            JSON.stringify({
                token,
                tokenExpiry,
                user
            })
        );
        // setLoginToken(token);
        setLoggedInUser(user);
    };
    const clearLoginToken = () => {
        window.sessionStorage.removeItem("token");
        // setLoginToken(null);
        setLoggedInUser(null);
    };

    useEffect(() => {
        let expiredTokenInterval = null;
        const startTokenChecker = () => {
            // console.log("Starting token checker...");
            expiredTokenInterval = setInterval(() => {
                // console.log("Doing token checker...");
                const encodedTokenDetails =
                    window.sessionStorage.getItem("token");
                if (encodedTokenDetails) {
                    // Check expiry
                    const tokenDetails = JSON.parse(encodedTokenDetails);
                    const expiryTs = new Date(tokenDetails.tokenExpiry);
                    const now = new Date();
                    if (now > expiryTs) {
                        clearLoginToken();
                    }
                } else {
                    clearLoginToken();
                }
            }, 10 * 1000);
        };
        const stopTokenChecker = () => {
            // console.log("Stopping token checker...");
            clearInterval(expiredTokenInterval);
            expiredTokenInterval = null;
        };

        // On load, check if we are logged in
        const encodedTokenDetails = window.sessionStorage.getItem("token");
        if (encodedTokenDetails) {
            // Check expiry
            const tokenDetails = JSON.parse(encodedTokenDetails);
            const expiryTs = new Date(tokenDetails.tokenExpiry);
            const now = new Date();
            if (now > expiryTs) {
                // This token has expired
                clearLoginToken();
            } else {
                // It is valid, use this user
                setLoggedInUser(tokenDetails.user);
            }
        } else {
            // Nothing in local storage
        }

        startTokenChecker();
        return () => {
            stopTokenChecker();
        };
    }, []);

    return (
        <StyleProvider>
            <ThemePicker variant="light">
                <BrowserRouter basename="/">
                    {loggedInUser === null ? (
                        <Login
                            setLoginToken={setLoginTokenAndUser}
                            clearLoginToken={clearLoginToken}
                            loggedInUser={loggedInUser}
                        />
                    ) : (
                        <ApolloProvider client={client}>
                            <Menu />
                            <ReactRouterDomRoutes>
                                <Route
                                    exact
                                    path="/"
                                    element={<LandingPage />}
                                />
                                <Route
                                    path="/inkopsansvar"
                                    element={<TeamleaderPage />}
                                />
                                <Route
                                    path="/servicetekniker"
                                    element={<TechnicianPage />}
                                />
                                <Route
                                    path="/godkann-datadelning"
                                    element={<ApproveDataSharing />}
                                />
                                <Route
                                    path="/skicka-feedback"
                                    element={<Feedback />}
                                />
                                <Route
                                    path="/releases"
                                    element={<ReleaseNotes />}
                                />
                                <Route
                                    path="/techstep-bestall"
                                    element={<TechstepForm />}
                                />
                                <Route
                                    path="/atea-bestall"
                                    element={<AteaForm />}
                                />
                                <Route path="/kontakta" element={<Contact />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route
                                    path="/valj-paket"
                                    element={<ChoosePackage />}
                                />
                                <Route
                                    path="/varfor-volvo-service"
                                    element={<WhyVolvoService />}
                                />
                                <Route
                                    path="/ping-pilot"
                                    element={<PingPilot />}
                                />
                            </ReactRouterDomRoutes>

                            <Footer />
                            <ScrollToTop />
                        </ApolloProvider>
                    )}
                </BrowserRouter>
            </ThemePicker>
        </StyleProvider>
    );
};
