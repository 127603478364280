import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Block, Text } from "vcc-ui";
import { Hero } from "../../components/LandingPage/Hero";
import { SlideAnimation } from "../../components/LandingPage/SlideAnimation";
import { MobileSlider } from "../../components/LandingPage/SlideAnimation/MobileSlider/index";
import { SlidingTestimonials } from "../../components/LandingPage/SlidingTestimonials";
import { TestimonialsMobileSlider } from "../../components/LandingPage/SlidingTestimonials/MobileSlider/index";
import { ThreeAnimatedTaglines } from "../../components/LandingPage/ThreeAnimatedTaglines";
import { VideoGrid } from "../../components/LandingPage/VideoGrid";

const Container = styled.div`
    margin: auto;
    display: block;
    text-align: center;
    h2 {
        font-size: 36px !important;
        line-height: normal;
        @media screen and (max-width: 768px) {
            font-size: 32px !important;
        }
    }
    h3 {
        font-size: 26px !important;
        line-height: normal;
        @media screen and (max-width: 768px) {
            font-size: 22px !important;
        }
    }
`;

export const LandingPage = () => {
    const { t } = useTranslation();
    return (
        <Container className="start">
            <Hero />

            <ThreeAnimatedTaglines>
                <VideoGrid />
            </ThreeAnimatedTaglines>

            <Block
                extend={{
                    margin: "0 16px 80px 16px",
                    "@media (max-width: 768px)": {
                        margin: "0 16px 70px 16px"
                    }
                }}
            >
                <Text
                    subStyle="emphasis"
                    as="h2"
                    extend={{
                        marginBottom: "15px",
                        "@media (max-width: 768px)": {
                            maxWidth: "300px",
                            margin: "0 auto 15px auto"
                        }
                    }}
                >
                    {t("landingPage.volvoVision.title")}
                </Text>
                <Text extend={{ maxWidth: "530px !important" }}>
                    {t("landingPage.volvoVision.paragraph1")}
                </Text>
                <br />
                <Text extend={{ maxWidth: "530px !important" }}>
                    {t("landingPage.volvoVision.paragraph2")}
                </Text>
            </Block>

            {window.innerWidth > 900 ? (
                <SlidingTestimonials />
            ) : (
                <TestimonialsMobileSlider />
            )}

            {window.innerWidth > 900 ? <SlideAnimation /> : <MobileSlider />}
        </Container>
    );
};
