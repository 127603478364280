import React, { useState } from "react";
import { Text, Button } from "vcc-ui";
import styled from "@emotion/styled";
import { ComparisonForm } from "../ComparisonForm";
import { motion } from "framer-motion";

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
`;

const SelectDataWrapper = styled.div`
    width: 190px;
    margin: 0 15px;
    @media screen and (max-width: 900px) {
        &:first-of-type {
            margin-right: 20px;
        }
        &:nth-of-type(3) {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        margin: 0;
        &:first-of-type {
            margin-right: 20px;
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
            display: none;
        }
    }
    .selectContent {
        h3 {
            font-size: 28px;
            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
        h4 {
            font-size: 24px;
            @media screen and (max-width: 768px) {
                font-size: 22px;
            }
        }
        h5 {
            font-size: 20px;
        }
        p {
            &.subHeader {
                font-size: 14px;
                font-weight: 500;
                color: var(--gray2);
            }
            &.spec {
                color: var(--gray3);
                font-weight: 400;
                max-width: 164px !important;
                margin-left: 0 !important;
            }
        }
        .header {
            position: relative;
            border-radius: 10px;
            padding-top: 30px;
            padding-bottom: 20px;
            min-height: 150px;
            margin-bottom: 30px;
            background-color: #f0f0f0;
            border: 1px solid rgba(0, 0, 0, 0);
            &.border {
                border: 1px solid rgba(0, 0, 0, 0.5);
                background-color: #fff;
                .headerContent {
                    margin-top: 0;
                }
            }
            .headerContent {
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 8px;
                margin-bottom: 8px;
                h5 {
                    margin-top: 5px;
                    margin-bottom: 11px;
                    &:last-of-type {
                        /* margin-bottom: 0; */
                    }
                }
                p,
                h5 {
                    padding-left: 15px;
                }
            }
        }
        .contentCol {
            margin-bottom: 30px;
            h5 {
                margin: 4px 0;
            }
            &.totalPrice {
                h3 {
                    margin-top: 2px;
                }
            }
        }
    }
`;

export const Select = (props) => {
    const [modal, setModal] = useState(false);

    document.documentElement.style.overflow = "inherit";

    return (
        <>
            <SelectDataWrapper>
                {props.data ? (
                    <div className="selectContent">
                        <div
                            className={
                                props.data.header.price1
                                    ? "header border"
                                    : "header"
                            }
                        >
                            <div className="headerContent">
                                <Text className="subHeader title1">
                                    {props.data.header.title1}
                                </Text>
                                <Text subStyle="emphasis" as="h5">
                                    {props.data.header.price1}
                                </Text>
                                <Text className="subHeader">
                                    {props.data.header.title2}
                                </Text>
                                <Text subStyle="emphasis" as="h5">
                                    {props.data.header.price2}
                                </Text>
                            </div>
                        </div>

                        <div
                            className="totalPrice contentCol"
                            style={{ height: props.totalPriceheight }}
                        >
                            <Text className="subHeader">
                                {props.data.total.title}
                            </Text>
                            <Text subStyle="emphasis" as="h3">
                                {props.data.total.price}
                            </Text>
                            <Text className="spec">
                                {props.data.total.priceText}
                            </Text>
                        </div>

                        <div
                            className="orderButton contentCol"
                            style={{ height: props.orderBtnHeight }}
                            onClick={() => setModal(true)}
                        >
                            <Button
                                style={
                                    props.data.button.type
                                        ? { width: "100%" }
                                        : { visibility: "hidden" }
                                }
                                intent="secondary"
                            >
                                {props.data.button.type}
                            </Button>
                            <Text
                                extend={{
                                    textAlign: "center",
                                    color: "var(--gray3)",
                                    marginTop: "12px"
                                }}
                                style={{ lineHeight: "normal" }}
                            >
                                {props.data.button.text}
                            </Text>
                        </div>

                        <div className="mobile contentCol">
                            <Text className="subHeader">
                                {props.data.mobile.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.mobile.type}
                            </Text>
                            <Text className="spec">
                                {props.data.mobile.text}
                            </Text>
                        </div>

                        <div className="watch contentCol">
                            <Text className="subHeader">
                                {props.data.watch.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.watch.type}
                            </Text>
                            <Text className="spec">
                                {props.data.watch.text}
                            </Text>
                        </div>

                        <div className="phoneCase contentCol">
                            <Text className="subHeader">
                                {props.data.phoneCase.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.phoneCase.type}
                            </Text>
                        </div>

                        <div
                            className="watchCase contentCol"
                            style={{ height: props.watchHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.watchCase.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.watchCase.type}
                            </Text>
                            <Text className="spec">
                                {props.data.watchCase.text}
                            </Text>
                        </div>

                        <div className="powerAdapter contentCol">
                            <Text className="subHeader">
                                {props.data.powerAdapter.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.powerAdapter.type}
                            </Text>
                        </div>

                        <div
                            className="warranty contentCol"
                            style={{ height: props.warrantyHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.warranty.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.warranty.type}
                            </Text>
                            <Text className="spec">
                                {props.data.warranty.text}
                            </Text>
                        </div>

                        <div
                            className="support contentCol"
                            style={{ height: props.supportHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.support.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.support.type}
                            </Text>
                            <Text className="spec">
                                {props.data.support.text}
                            </Text>
                        </div>

                        <div
                            className="dep contentCol"
                            style={{ height: props.depHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.dep.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.dep.type}
                            </Text>
                            <Text className="spec">{props.data.dep.text}</Text>
                        </div>

                        <div
                            className="assetSystem contentCol"
                            style={{ height: props.assetHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.assetSystem.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.assetSystem.type}
                            </Text>
                            <Text className="spec">
                                {props.data.assetSystem.text}
                            </Text>
                        </div>

                        <div
                            className="repair contentCol"
                            style={{ height: props.repairHeight }}
                        >
                            <Text className="subHeader">
                                {props.data.repair.title}
                            </Text>
                            <Text subStyle="emphasis" as="h5">
                                {props.data.repair.type}
                            </Text>
                            <Text className="spec">
                                {props.data.repair.text}
                            </Text>
                        </div>
                        <div
                            className="orderButton contentCol"
                            style={{ height: props.orderBtnHeight }}
                            onClick={() => setModal(true)}
                        >
                            <Button
                                style={
                                    props.data.button.type
                                        ? { width: "100%" }
                                        : { visibility: "hidden" }
                                }
                                intent="secondary"
                            >
                                {props.data.button.type}
                            </Button>
                            <Text
                                extend={{
                                    textAlign: "center",
                                    color: "var(--gray3)",
                                    marginTop: "12px"
                                }}
                                style={{ lineHeight: "normal" }}
                            >
                                {props.data.button.text}
                            </Text>
                        </div>
                    </div>
                ) : null}
            </SelectDataWrapper>

            {modal && (
                <motion.div
                    animate={modal ? "open" : "closed"}
                    transition={{ duration: 0.2 }}
                    variants={variants}
                    style={{ opacity: 0, zIndex: 101 }}
                >
                    <ComparisonForm
                        close={() => setModal(false)}
                        data={props.data}
                    />
                    <Overlay onClick={() => setModal(false)} />
                </motion.div>
            )}
        </>
    );
};
