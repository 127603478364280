import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Block, Icon, Text } from "vcc-ui";
import Atea from "../../assets/atea.png";
import AteaPdf2 from "../../assets/Atea_Apple_MKN53KS_A_sv.pdf";
import AteaPdf1 from "../../assets/Atea_erbjudande_iPhone_Apple_Watch_20211105.pdf";
import TechStep from "../../assets/techstep.png";
import TechStepPdf2 from "../../assets/Techstep_Offert_Mobila_enheter_iPhone13_VCS.pdf";
import TechStepPdf1 from "../../assets/TS_Erbjudande_VCS_iPhone13_Watch_7.pdf";
import { Comparison } from "../../components/Comparison";
import { Hero } from "../../components/Hero";

const Container = styled.div`
    padding: 20px;
    padding-bottom: 90px;

    .suppliers {
        max-width: 850px;
        margin: 50px 0;
        margin-top: 59px;
        border-radius: 10px;
        padding: 25px 16px;
        background-color: #f0f0f0;
        @media screen and (min-width: 769px) {
            margin-right: auto;
            margin-left: auto;
        }
        .subheader {
            font-size: 13px;
            color: var(--gray3);
            text-align: center;
        }

        p {
            font-size: 20px;
            text-align: center;
            margin-top: 18px;
            max-width: 480px !important;
            color: var(--gray3);
            font-weight: 400;
        }

        .logos {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            img {
                width: 50%;
                max-width: 98px;
                margin: 0 10px;
                padding-top: 20px;
                &:first-of-type {
                    max-width: 133px;
                }
            }
        }
    }
`;

const PdfsWrapper = styled.div`
    div {
        display: flex;
        flex-direction: column;
        width: fit-content;
        justify-content: center;
        margin: auto;
        padding-bottom: 30px;
        a {
            display: flex;
            align-items: center;
            em {
                margin-right: 5px;
            }
        }
    }
`;

export const ChoosePackage = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Hero
                subtitle={t("getDevices.heroSubtitle")}
                title={t("getDevices.heroTitle")}
                text={t("getDevices.heroText")}
                headerWidth="330px"
                textWidth="500px"
                marginBottom="66px"
            />

            <div className="suppliers">
                <Text subStyle="emphasis" className="subheader">
                    {t("getDevices.retailers")}
                </Text>
                <div className="logos">
                    <img src={TechStep} alt="techstep" />
                    <img src={Atea} alt="atea" />
                </div>
                <Text as="p">{t("getDevices.packageInfo")}</Text>
            </div>

            <Text
                style={{
                    fontSize: "36px",
                    textAlign: "center",
                    margin: "auto",
                    lineHeight: "normal",
                    paddingBottom: "50px",
                    paddingTop: "20px"
                }}
                extend={{ "@media (max-width: 480px)": { maxWidth: "300px" } }}
                subStyle="emphasis"
            >
                Jämför och beställ
            </Text>
            <Comparison />

            <Text
                style={{
                    fontSize: "36px",
                    textAlign: "center",
                    margin: "auto",
                    lineHeight: "normal",
                    paddingBottom: "30px",
                    paddingTop: "20px"
                }}
                subStyle="emphasis"
            >
                Produktblad
            </Text>

            <PdfsWrapper>
                <div>
                    <a href={AteaPdf1} arrow="right" style={{ color: "black" }}>
                        <Text subStyle="emphasis" style={{ fontSize: "18px" }}>
                            Ateas erbjudanden
                        </Text>
                        <Icon type="navigation-chevronforward-24" />
                    </a>

                    <Text extend={{ color: "var(--gray3)" }}>PDF</Text>
                </div>
                <div>
                    <a href={AteaPdf2} arrow="right" style={{ color: "black" }}>
                        <Text subStyle="emphasis" style={{ fontSize: "18px" }}>
                            Ateas specifikationer
                        </Text>
                        <Icon type="navigation-chevronforward-24" />
                    </a>
                    <Text extend={{ color: "var(--gray3)" }}>PDF</Text>
                </div>
                <div>
                    <a
                        href={TechStepPdf1}
                        arrow="right"
                        style={{ color: "black" }}
                    >
                        <Text subStyle="emphasis" style={{ fontSize: "18px" }}>
                            Techsteps erbjudanden
                        </Text>
                        <Icon type="navigation-chevronforward-24" />
                    </a>
                    <Text extend={{ color: "var(--gray3)" }}>PDF</Text>
                </div>
                <div>
                    <a
                        href={TechStepPdf2}
                        arrow="right"
                        style={{ color: "black" }}
                    >
                        <Text subStyle="emphasis" style={{ fontSize: "18px" }}>
                            Att beställa från Techstep
                        </Text>
                        <Icon type="navigation-chevronforward-24" />
                    </a>
                    <Text extend={{ color: "var(--gray3)" }}>PDF</Text>
                </div>
            </PdfsWrapper>

            <Text
                style={{
                    fontSize: "36px",
                    textAlign: "center",
                    margin: "auto",
                    lineHeight: "normal",
                    paddingBottom: "30px",
                    paddingTop: "26px"
                }}
                subStyle="emphasis"
            >
                Svårt att välja?
            </Text>

            <Block extend={{ display: "flex", justifyContent: "center" }}>
                <Link
                    to="/kontakta"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Text
                        subStyle="emphasis"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                    >
                        Kontakta oss
                    </Text>
                    <Icon type="navigation-chevronforward-24" />
                </Link>
            </Block>
        </Container>
    );
};
