import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Block, Text } from "vcc-ui";
import { Waypoint } from "react-waypoint";
import slideImage1 from "../../../assets/img-slide-1.png";
import slideImage2 from "../../../assets/img-slide-2.png";
import slideImage3 from "../../../assets/img-slide-3.png";
import slideImage4 from "../../../assets/img-slide-4.1.png";
import "./styles.scss";


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: auto auto 100px auto;
    padding: 80px;
    background-color: #fafafa;
    border-radius: 2px;
    img {
        width: 100%;
        max-width: 270px;
    }
`;

const Navigation = styled.div`
    text-align: left;
    padding-right: 150px;
    @media screen and (max-width: 1024px) {
        width: 50%;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 270px;
    height: 610px;
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const NavigationRow = styled.div`
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 50px 50px 0px;
    border-top: 2px solid #5a5a5a;
    display: flex;
    flex-flow: row;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.3s ease;
    &.active {
        opacity: 1 !important;
    }
    &:hover {
        opacity: 1 !important;
    }
    p {
        text-align: left;
        margin: 0 !important;
        &:last-of-type {
            width: 250px !important;
            padding-left: 50px;
        }
    }
`;


export const SlideAnimation = () => {

    const [currentImage, setCurrentImage] = useState(1);
    const [enter, setEnter] = useState(false);

    const timer1 = useRef(0);
    const timer2 = useRef(0);
    const timer3 = useRef(0);

    useEffect(() => {
        if(enter) {
            timer1.current = window.setTimeout(() => {
                setImage(2);
            }, 1500);

            timer2.current = window.setTimeout(() => {
                setImage(3);
            }, 5500);

            timer3.current = window.setTimeout(() => {
                setImage(4);
            }, 9500);
        }
    }, [enter]);

    const clearTimers = () => {
        clearTimeout(timer1.current);
        clearTimeout(timer2.current);
        clearTimeout(timer3.current);
    };


    const getImage = () => {
        let image = slideImage1;

        if (currentImage === 1) {
            image = slideImage1;
        } else if (currentImage === 2) {
            image = slideImage2;
        } else if (currentImage === 3) {
            image = slideImage3;
        } else {
            image = slideImage4;
        }

        return image;
    };
    
    const setImage = (index: SetStateAction<number>) => {
        const image = document.getElementById("imageSlider");
        if (image) {
            image.classList.remove("slideIn");
            image.classList.add("slideOut");
        }

        window.setTimeout(() => {
            setCurrentImage(index);
        }, 500);

        window.setTimeout(() => {
            if (image) {
                image.classList.remove("slideOut");
                image.classList.add("slideIn");
            }
        }, 500);
    };

    return (
        <Wrapper>
            <Waypoint onEnter={() => setEnter(true)} bottomOffset="100px" />
            <Navigation>
                <Block style={{ marginBottom: "70px" }}>
                    <Text style={{ marginLeft: 0 }}>FUNKTIONER</Text>
                    <Text
                        subStyle="emphasis"
                        variant="cook"
                        style={{ marginLeft: 0, marginTop: "20px" }}
                    >
                        En app i ständig utveckling
                    </Text>
                </Block>

                <NavigationRow
                    className={currentImage === 1 ? "active" : ""}
                    onClick={() => { setImage(1); clearTimers(); }}
                >
                    <Text subStyle="emphasis" variant="ootah" as="p">
                        Planera din <br /> arbetsdag
                    </Text>
                    <Text>Dagens schema i handen/fickan.</Text>
                </NavigationRow>
                <NavigationRow
                    className={currentImage === 2 ? "active" : ""}
                    onClick={() => { setImage(2); clearTimers(); }}
                >
                    <Text subStyle="emphasis" variant="ootah" as="p">
                        Hantera <br /> arbetsordrar
                    </Text>
                    <Text>Var förberedd när kunden kommer.</Text>
                </NavigationRow>
                <NavigationRow
                    className={currentImage === 3 ? "active" : ""}
                    onClick={() => { setImage(3); clearTimers(); }}
                >
                    <Text subStyle="emphasis" variant="ootah" as="p">
                        Återkoppla mot <br /> kunden
                    </Text>
                    <Text>Kontakta kunden direkt.</Text>
                </NavigationRow>
                <NavigationRow
                    className={currentImage === 4 ? "active" : ""}
                    onClick={() => { setImage(4); clearTimers(); }}
                >
                    <Text subStyle="emphasis" variant="ootah" as="p">
                        + På gång
                    </Text>
                    <Text>
                        vi jobbar för fullt med att rulla ut fler värdefulla
                        funktioner som{" "}
                        <Text subStyle="emphasis">Sökfunktion</Text>,{" "}
                        <Text subStyle="emphasis">Mottagningsbevis</Text> och{" "}
                        <Text subStyle="emphasis">Tilläggsjobb.</Text>
                    </Text>
                </NavigationRow>
            </Navigation>
            <ImageWrapper>
                <motion.img src={getImage()} alt="slide" id="imageSlider" />
            </ImageWrapper>
        </Wrapper>
    );
};
