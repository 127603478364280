import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "vcc-ui";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div`
    padding: 40px 0 30px 0;
    background-color: #000000;
    @media screen and (min-width: 769px) {
        padding: 50px 0 40px 0;
    }
`;

const Group = styled.div`
    margin-bottom: 50px;
    // padding-left: 16px;
    @media screen and (min-width: 769px) {
        text-align: center;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    .header {
        color: #ffffff;
        margin-bottom: 20px;
        text-align: center;
    }
    .nav {
        margin-bottom: 10px;
        color: #ffffff;
        font-size: 20px;
        &:last-of-type {
            //margin-bottom: 0;
        }
    }
    a {
        width: max-content;
        display: block;
        margin: auto;
    }
`;

export const Footer = () => {

    const { t } = useTranslation();

    return (
        <Container>
            <Group>
                <Text className="header" as="p" subStyle="emphasis">
                    VOLVO SERVICE
                </Text>
                <Link to="/varfor-volvo-service">
                    <Text className="nav" as="p">
                        {t("Menu.whyVolvoService")}
                    </Text>
                </Link>
                <Link to="/inkopsansvar">
                    <Text className="nav" as="p">
                        {t("Menu.getApp")}
                    </Text>
                </Link>
                <Link to="/valj-paket">
                    <Text className="nav" as="p">
                        {t("Menu.order")}
                    </Text>
                </Link>
            </Group>

            <Group>
                <Text className="header" as="p" subStyle="emphasis">
                    {t("Menu.helpHeader")}
                </Text>
                <Link to="/servicetekniker">
                    <Text className="nav" as="p">
                        {t("Menu.guide")}
                    </Text>
                </Link>
                <Link to="/faq">
                    <Text className="nav" as="p">
                        {t("Menu.faq")}
                    </Text>
                </Link>
                <Link to="/kontakta">
                    <Text className="nav" as="p">
                        {t("Menu.techSupport")}
                    </Text>
                </Link>
            </Group>

            <Group>
                <Text className="header" as="p" subStyle="emphasis">
                    {t("Menu.feedbackHeader")}
                </Text>
                <Link to="/skicka-feedback">
                    <Text className="nav" as="p">
                        {t("Menu.sendFeedback")}
                    </Text>
                </Link>
                <Link to="/releases">
                    <Text className="nav" as="p">
                        {t("Menu.releaseNotes")}
                    </Text>
                </Link>
                <Link to="/kontakta">
                    <Text className="nav" as="p">
                        {t("Menu.contact")}
                    </Text>
                </Link>
            </Group>
        </Container>
    );
};
