import React from "react";
import { Text } from "vcc-ui";
import styled from "@emotion/styled";

const Container = styled.div`
    padding: 0 24px;
    margin-top: 115px;
    margin-bottom: 82px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;

    .imgWrapper {
        margin: auto;
        text-align: center;
        .logo {
            width: 100%;
        }
    }
`;

const Content = styled.div`
    h1 {
        max-width: 450px;
        margin: auto;
    }
    h4 {
        margin-top: 10px;
        margin-right: auto;
        margin-left: auto;
    }
    p {
        font-size: 15px;
        color: var(--gray2);
        margin-bottom: 22px;
    }
`;

export const Hero = ({ title, text, subtitle, marginBottom, headerWidth, textWidth, logo, logoWidth }) => {
    return (
        <Container style={{ marginBottom: marginBottom }}>
            <Content>
                {subtitle && (
                    <Text as="p" subStyle="emphasis">
                        {subtitle}
                    </Text>
                )}

                {logo && (
                    <div className="imgWrapper">
                        <img
                            src={logo}
                            alt="techstep"
                            className="logo"
                            style={{ maxWidth: logoWidth }}
                        />
                    </div>
                )}

                <Text
                    as="h1"
                    variant="cook"
                    subStyle="emphasis"
                    style={{ maxWidth: headerWidth }}
                >
                    {title}
                </Text>

                {text && (
                    <Text as="h4" subStyle="emphasis" style={{ maxWidth: textWidth }}>
                        {text}
                    </Text>
                )}
            </Content>
        </Container>
    );
};
