import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
//import commonEN from "./locales/en/common.json";
import commonSV from "./locales/sv/common.json";

const resources = {
    sv: { common: commonSV },
    //en: { common: commonEN }
};

const options = {
    order: ["querystring", "localStorage", "path", "navigator"],
    lookupQuerystring: "language"
};

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        language: localStorage.getItem("language"),
        detection: options,
        resources: resources,
        ns: ["common"],
        defaultNS: "common",
        fallbackLng: "sv",
        supportedLngs: ["sv"],
        interpolation: {
            escapeValue: false
        },
        debug: false
    });

export default i18n;
