import img1 from "../../../assets/random/image-1.jpg";
import img2 from "../../../assets/random/image-2.jpg";
import img3 from "../../../assets/random/image-3.jpg";
import img4 from "../../../assets/random/image-4.jpg";
import img5 from "../../../assets/random/image-5.jpg";
import img6 from "../../../assets/random/image-6.jpg";
import img7 from "../../../assets/random/image-7.jpg";
import img8 from "../../../assets/random/image-8.jpg";

//
export function getRandomImages(arrayOfNumbers: number[]): string[][] {
    const result: string[][] = [];
    const images = [img1, img2, img3, img4, img5, img6, img7, img8];

    for (let i = 0; i < arrayOfNumbers.length; i++) {
        result.push([]);
        for (let j = 0; j < arrayOfNumbers[i]; j++) {
            if (images.length < 1) break;
            const randomIndex = Math.floor(Math.random() * images.length);
            const img = images.splice(randomIndex, 1);
            if (img.length > 0) result[i].push(img[0]);
        }
    }

    return result.map((arr) => arr.filter((string) => string));
}
