import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block, Click, Icon, Spacer, Text, View } from "vcc-ui";
import VideoCover from "../../../assets/videos/cover.jpg";
import VideoSrc from "../../../assets/videos/movie.mp4";
import "./styles.scss";

export function Hero() {
    const { t } = useTranslation();

    const videoElement = useRef<HTMLVideoElement>(null);
    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(true);

    useEffect(() => {
        if (videoElement.current) {
            if (videoElement.current.paused) {
                setPlaying(false);
            } else {
                setPlaying(true);
            }

            if (videoElement.current.muted) {
                setMuted(true);
            } else {
                setMuted(false);
            }
        }
    }, [videoElement]);

    function handleClick(e: React.MouseEvent<HTMLVideoElement>) {
        if (e.currentTarget.paused) {
            e.currentTarget.play();
            setPlaying(true);
        } else {
            e.currentTarget.pause();
            setPlaying(false);
        }
    }

    function handleMuteUnmute() {
        if (videoElement.current) {
            if (videoElement.current.muted) {
                videoElement.current.muted = false;
                setMuted(false);
            } else {
                videoElement.current.muted = true;
                setMuted(true);
            }
        }
    }

    function PlaybackControl({ playing }) {
        return (
            <View
                extend={{
                    pointerEvents: "none"
                }}
            >
                {!playing ? (
                    <div className="icon-play">
                        <Icon type="media-play-32" color="inverted" />
                    </div>
                ) : null}
            </View>
        );
    }

    function MuteUnmuteControl({ muted }) {
        return (
            <Click
                onClick={handleMuteUnmute}
                aria-label="Mute"
                aria-pressed={muted}
                extend={{
                    position: "absolute",
                    bottom: "2em",
                    left: "2em"
                }}
            >
                {muted ? (
                    <svg
                        width="36"
                        height="36"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                    >
                        <path
                            fill="white"
                            d="M3.61 6.41 9.19 12H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.14l7.41 7.47A2 2 0 0 0 18 32a2 2 0 0 0 .76-.15A2 2 0 0 0 20 30v-7.23l5.89 5.89c-.25.15-.49.29-.75.42a1 1 0 0 0 .9 1.79 14.4 14.4 0 0 0 1.31-.75l2.28 2.28L31 31 5 5ZM18 30l-7.73-7.77a1 1 0 0 0-.71-.23H4v-8h5.64a1 1 0 0 0 .71-.3l.26-.26L18 20.81Z"
                        />
                        <path
                            fill="white"
                            d="M24.89 6.69A12.42 12.42 0 0 1 29 26.1l1.42 1.42a14.42 14.42 0 0 0-4.66-22.64 1 1 0 1 0-.87 1.8Z"
                        />
                        <path
                            fill="white"
                            d="M22.69 12.62A6.27 6.27 0 0 1 25.8 18a6.17 6.17 0 0 1-1.24 3.71L26 23.13A8.15 8.15 0 0 0 27.8 18a8.28 8.28 0 0 0-4.1-7.11 1 1 0 1 0-1 1.73Z"
                        />
                        <path
                            fill="white"
                            d="M18 6v9.15l2 2V6a2 2 0 0 0-3.42-1.41L12 9.17l1.41 1.41Z"
                        />
                        <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                ) : (
                    <svg
                        className="icon-mute"
                        width="36"
                        height="36"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                    >
                        <path
                            fill="white"
                            d="M23.41 25.25a1 1 0 0 1-.54-1.85 6.21 6.21 0 0 0-.19-10.65 1 1 0 1 1 1-1.73 8.21 8.21 0 0 1 .24 14.06 1 1 0 0 1-.51.17Z"
                        />
                        <path
                            fill="white"
                            d="M25.62 31.18a1 1 0 0 1-.45-1.89A12.44 12.44 0 0 0 25 6.89a1 1 0 1 1 .87-1.8 14.44 14.44 0 0 1 .24 26 1 1 0 0 1-.49.09Z"
                        />
                        <path
                            fill="white"
                            d="M18 32.06a2 2 0 0 1-1.42-.59L9.14 24H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h5.22l7.33-7.39A2 2 0 0 1 20 6v24a2 2 0 0 1-1.24 1.85 2 2 0 0 1-.76.21ZM4 14v8h5.56a1 1 0 0 1 .71.3L18 30.06V6l-7.65 7.7a1 1 0 0 1-.71.3Zm14-8Z"
                        />
                        <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                )}
            </Click>
        );
    }

    return (
        <>
            <Text
                variant="peary"
                extend={{
                    fontSize: "64px !important",
                    marginTop: "100px",
                    "@media (max-width: 768px)": {
                        fontSize: "44px !important"
                    }
                }}
            >
                {t("landingPage.pageTitle")}
            </Text>
            <Spacer size={4} />
            <Text
                variant="columbus"
                subStyle="emphasis"
                className="subHeader"
                extend={{
                    textTransform: "uppercase",
                    margin: "10px 0 30px 0"
                }}
            >
                {t("landingPage.subtitle")}
            </Text>

            <Spacer size={4} />

            <Text
                as="p"
                variant="ootah"
                subStyle="emphasis"
                extend={{
                    textAlign: "center",
                    fontSize: "26px !important"
                }}
            >
                {t("landingPage.ingress")}
            </Text>

            <Block
                className="video-wrapper"
                extend={{
                    position: "relative",
                    maxWidth: "1200px",
                    margin: "100px auto 0 auto",
                    "@media (max-width: 768px)": {
                        margin: "50px auto 0 auto"
                    }
                }}
            >
                <Block
                    ref={videoElement}
                    onClick={handleClick}
                    as="video"
                    width="100%"
                    poster={VideoCover}
                    controls={false}
                    muted={muted}
                >
                    <Block as="source" src={VideoSrc} type="video/mp4" />
                </Block>
                <PlaybackControl playing={playing} />
                <MuteUnmuteControl muted={muted} />
            </Block>
        </>
    );
}
