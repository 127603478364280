import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Icon, Text } from "vcc-ui";
import { Hero } from "../../components/Hero";

const Container = styled.div`
    margin: 16px;
    padding-bottom: 70px;
`;

const Start = styled.div`
    text-align: center;
    p {
        font-size: 14px;
        padding-top: 15px;
        color: var(--gray3);
        max-width: 360px !important;
        margin: auto;
    }
    button {
        min-width: 200px;
    }
`;

const TextBlock = styled.div`
    text-align: center;
    padding-top: 60px;
    @media screen and (min-width: 780px) {
        max-width: 540px;
        margin: auto;
    }
    h4 {
        margin-bottom: 10px;
    }
    .linkWrapper {
        margin-top: 35px;
        display: inline-block;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                margin-left: 3px;
                padding-top: 4px;
            }
        }
    }
`;

const Ready = styled.div`
    text-align: center;
    margin-top: 74px;
    p {
        color: var(--gray3);
        font-size: 14px;
        max-width: 360px !important;
        margin: auto;
    }
    button {
        margin-top: 30px;
        margin-bottom: 15px;
        min-width: 200px;
    }
`;

export const ApproveDataSharing = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Hero
                subtitle={t("ApproveDataSharing.heroSubtitle")}
                title={t("ApproveDataSharing.heroTitle")}
                text={t("ApproveDataSharing.heroText")}
                marginBottom="51px"
                textWidth="500px"
            />
            <Start>
                <a href="https://vcrsprod.service-now.com/sp?id=sc_category">
                    <Button intent="secondary">
                        {t("ApproveDataSharing.heroButton")}
                    </Button>
                </a>
                <Text as="p">{t("ApproveDataSharing.heroButtonDesc")}</Text>
            </Start>

            <TextBlock>
                <Text as="h4" subStyle="emphasis">
                    {t("ApproveDataSharing.textBlock1Header")}
                </Text>
                <Text>{t("ApproveDataSharing.textBlock1Text")}</Text>
            </TextBlock>

            <TextBlock>
                <Text as="h4" subStyle="emphasis">
                    {t("ApproveDataSharing.textBlock2Header")}
                </Text>
                <Text>{t("ApproveDataSharing.textBlock2Text")}</Text>

                <div className="linkWrapper">
                    <Link to="/">
                        <Text as="span" variant="hillary" subStyle="emphasis">
                            {t("ApproveDataSharing.guideLink")}
                        </Text>
                        <Icon type="navigation-chevronforward-16" />
                    </Link>
                </div>
            </TextBlock>

            <Ready>
                <Text as="h2" subStyle="emphasis" style={{ fontSize: "32px" }}>
                    {t("ApproveDataSharing.readyHeader")}
                </Text>
                <a href="https://vcrsprod.service-now.com/sp?id=sc_category">
                    <Button intent="secondary">
                        {t("ApproveDataSharing.readyButton")}
                    </Button>
                </a>

                <Text as="p">{t("ApproveDataSharing.readyButtonDesc")}</Text>
            </Ready>
        </Container>
    );
};
