import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import React, { useState } from "react";
//import { useTranslation } from "react-i18next";
import { Button, Text, TextArea, TextInput } from "vcc-ui";
import CloseIcon from "../../assets/close.png";
import { SEND_ORDER } from "../../graphql/mutations/sendOrder";
import { OrderConfirm } from "../OrderConfirm";

interface Props {
    close: () => void;
    data: any;
}

enum Companies {
    ATEA,
    TECHSTEP
}

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 20px;
    padding-bottom: 90px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 101;
    padding: 40px;
    border-radius: 4px;
    @media screen and (max-width: 768px), screen and (max-height: 900px) {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    .comparisonForm {
        @media screen and (max-width: 768px), screen and (max-height: 900px) {
            position: relative;
        }
        .close {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 102;
            @media screen and (max-width: 768px),
                screen and (max-height: 900px) {
                right: 20px;
                top: 15px;
            }
            &:hover {
                cursor: pointer;
            }
            img {
                width: 20px;
            }
        }
        form {
            max-width: 500px;
            margin: auto;
            @media screen and (max-width: 768px),
                screen and (max-height: 900px) {
                overflow-y: scroll;
                max-height: calc(100vh - 150px);
                padding: 20px;
                margin-top: 70px;
                /* box-sizing: border-box; */
            }
            .inputWrapper {
                margin-bottom: 25px;
                button {
                    margin-top: 15px;
                }
                p {
                    font-size: 20px;
                    margin-bottom: 6px;
                }
            }
            .feedback {
                text-align: center;
                max-width: 280px;
                margin: auto;
                margin-top: 14px;
            }
        }
    }
`;

export const ComparisonForm = ({ close, data }: Props) => {
    // const { t } = useTranslation();

    const [createOrder] = useMutation(SEND_ORDER, {
        onCompleted() {
            setSent(true);

            setName("");
            setEmail("");
            setAmount("");
            setCompany("");
            setText("");
        },
        onError(error) {
            console.log(error);
            setSendError(true);
            setSent(false);
        }
    });

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [amount, setAmount] = useState("");
    const [text, setText] = useState(data.header.category);

    const [formValid, setFormValid] = useState(true);
    const [formSent, setSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    const validateEmail = (email) => {
        const rule = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
        return rule.test(email);
    };

    const validateForm = () => {
        if (
            name.length > 1 &&
            validateEmail(email) &&
            amount.length > 0 &&
            company !== "select"
        ) {
            console.log("valid");
            setFormValid(true);
            sendForm();
        } else {
            console.log("not valid");
            setFormValid(false);
        }
    };

    const sendForm = () => {
        let selectedCompany;
        if (data.header.category.toLowerCase().includes("atea")) {
            selectedCompany = Companies.ATEA;
        } else {
            selectedCompany = Companies.TECHSTEP;
        }

        let nameOfCompany = Companies[selectedCompany];

        createOrder({
            variables: {
                input: {
                    dealer: company,
                    email: email,
                    extra: text,
                    name: name,
                    recipient: nameOfCompany,
                    units: Number(amount)
                }
            }
        });
    };

    document.documentElement.style.overflow = "hidden";

    return (
        <Container>
            {!formSent && (
                <div className="comparisonForm">
                    <div onClick={close} className="close">
                        <img src={CloseIcon} alt="close" />
                    </div>

                    <form>
                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                Namn
                            </Text>
                            <TextInput
                                name="name"
                                label="Namn.."
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                isValid={
                                    !formValid && name.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                Handlare & anläggning
                            </Text>
                            <TextInput
                                name="company"
                                label="Handlare & anläggning.."
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                isValid={
                                    !formValid && company.length <= 1
                                        ? false
                                        : true
                                }
                            ></TextInput>
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                E-post
                            </Text>
                            <TextInput
                                name="email"
                                label="E-post.."
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                isValid={
                                    !formValid && !validateEmail(email)
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                Antal enheter
                            </Text>
                            <TextInput
                                name="amount"
                                label="Antal enheter.."
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                isValid={
                                    !formValid && amount.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                Övrig information
                            </Text>
                            <TextArea
                                name="info"
                                label="Övrig information.."
                                onChange={(e) => setText(e.target.value)}
                                value={text}
                                style={{ minHeight: "120px" }}
                            />
                        </div>

                        <Button
                            intent="secondary"
                            onClick={() => validateForm()}
                        >
                            Skicka beställning
                        </Button>
                        {sendError && (
                            <Text
                                style={{
                                    color: "#bf2012",
                                    marginTop: "15px",
                                    textAlign: "center"
                                }}
                            >
                                Något gick fel, vänligen försök igen.
                            </Text>
                        )}
                    </form>
                </div>
            )}

            {formSent && <OrderConfirm email={email} />}
        </Container>
    );
};
