import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "vcc-ui";

const Container = styled.div`
    margin-top: 140px;
    text-align: center;

    h1 {
        font-size: 36px;
    }
    h3 {
        font-size: 24px;
        margin-top: 80px;
        margin-bottom: 20px;
    }
`;

export const FeedbackConfirm = ({ email }) => {
    window.scrollTo(0, 0);
    const { t } = useTranslation();

    return (
        <Container>
            <Text as="h1" subStyle="emphasis">
                {t("FeedbackForm.confirm")}
            </Text>
        </Container>
    );
};
