import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block, Text, View } from "vcc-ui";
import { useElementScrollPos } from "../../../hooks/useElementScrollPos";
import { PinnedView } from "../PinnedView";
import "../variables.scss";
import { getRandomImages } from "./getRandomImages";
import { getRandomQuotes } from "./getRandomQuotes";
import "./styles.scss";

export function SlidingTestimonials() {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    const percent = useElementScrollPos(ref);

    const [currentClassName, setCurrentClassName] = useState<
        "" | "one" | "two" | "three" | "out"
    >("");

    const [randomQuotes, setRandomQuotes] = useState<number[]>([]);
    const [randomImagesArrays, setRandomImagesArrays] = useState<string[][]>(
        []
    );

    useEffect(() => {
        setRandomImagesArrays(getRandomImages([3, 3]));
        setRandomQuotes(getRandomQuotes(3));
    }, []);

    useEffect(() => {
        if (percent >= -0.3 && percent < 0.1) {
            setCurrentClassName("one");
        }
        if (percent >= 0.1 && percent < 0.55) {
            setCurrentClassName("two");
        }
        if (percent >= 0.55 && percent < 1) {
            setCurrentClassName("three");
        }
        if (percent >= 1) {
            setCurrentClassName("out");
        }
    }, [percent]);

    return (
        <PinnedView ref={ref} totalViewportHeights={2}>
            <View
                alignItems="center"
                justifyContent="center"
                padding={2}
                extend={{ height: "calc(100vh - 60px)" }}
            >
                <View
                    spacing={8}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    minWidth={768}
                    maxWidth={1000}
                    extend={{ position: "relative" }}
                >
                    {/* ***************** Quotes ***************** */}
                    {randomQuotes?.length > 0 && (
                        <Block
                            className={
                                "animation-wrapper-variables text-animation-wrapper " +
                                currentClassName
                            }
                            extend={{
                                display: "grid",
                                justifyContent: "start",
                                maxWidth: "485px"
                            }}
                        >
                            {randomQuotes.map((number) => (
                                <View
                                    key={`quote-` + number}
                                    spacing={2}
                                    justifyContent="flex-start"
                                    extend={{ gridArea: "1/-1" }}
                                >
                                    <Text
                                        as="blockquote"
                                        variant="hillary"
                                        subStyle="emphasis"
                                        extend={{
                                            textAlign: "left",
                                            marginRight: "auto"
                                        }}
                                    >
                                        {t(
                                            `landingPage.slidingQuotes.${number}.text`
                                        )}
                                    </Text>
                                    <Text
                                        as="span"
                                        extend={{
                                            textAlign: "left",
                                            marginRight: "auto"
                                        }}
                                    >
                                        {t(
                                            `landingPage.slidingQuotes.${number}.name`
                                        )}
                                    </Text>
                                </View>
                            ))}
                        </Block>
                    )}

                    {/* ***************** Images wrapper ***************** */}
                    <View direction="row" spacing={1} minHeight={725}>
                        {/* ***************** ImageBlock 1 ***************** */}
                        {randomImagesArrays?.[0]?.length > 0 && (
                            <Block
                                className={
                                    "animation-wrapper-variables images1-animation-wrapper " +
                                    currentClassName
                                }
                                extend={{
                                    display: "grid",
                                    alignSelf: "flex-start"
                                }}
                            >
                                {randomImagesArrays[0].map((img) => (
                                    <Block
                                        as="img"
                                        key={img}
                                        height={377}
                                        width={253}
                                        src={img}
                                        extend={{
                                            objectFit: "cover",
                                            gridArea: "1/-1"
                                        }}
                                    />
                                ))}
                            </Block>
                        )}
                        {/* ***************** ImageBlock 2 ***************** */}
                        {randomImagesArrays?.[1]?.length > 0 && (
                            <Block
                                className={
                                    "animation-wrapper-variables images2-animation-wrapper " +
                                    currentClassName
                                }
                                extend={{
                                    display: "grid",
                                    alignSelf: "flex-end"
                                }}
                            >
                                {randomImagesArrays[1].map((img) => (
                                    <Block
                                        as="img"
                                        key={img}
                                        height={377}
                                        width={253}
                                        src={img}
                                        extend={{
                                            objectFit: "cover",
                                            gridArea: "1/-1"
                                        }}
                                    />
                                ))}
                            </Block>
                        )}
                    </View>

                    <Text extend={{ position: "absolute", left: 0, bottom: 0 }}>
                        {currentClassName === "" && "1/3"}
                        {currentClassName === "one" && "1/3"}
                        {currentClassName === "two" && "2/3"}
                        {currentClassName === "three" && "3/3"}
                        {currentClassName === "out" && "3/3"}
                    </Text>
                </View>
            </View>
        </PinnedView>
    );
}
