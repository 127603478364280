import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Showdown from "showdown";
import { Block, Button, Text } from "vcc-ui";
import HeroImg from "../../assets/volvo-service-hero.jpg";
import { LoadingPage } from "../../components/LoadingPage";
import { GET_START } from "../../graphql/queries";

const Container = styled.div`
    margin: auto;
    display: block;
    text-align: center;
`;

const Hero = styled.div`
    background-color: #dfe6ed;
    padding: 0 20px;
    margin-bottom: 94px;
    h1 {
        padding-top: 135px;
    }
    .subHeader {
        padding-top: 10px;
        font-size: 24px;
    }
`;

const ImageBlock = styled.div`
    padding: 0 16px 90px 16px;
    p {
        display: block;
        max-width: 270px;
        text-align: center;
        margin: auto;
        @media screen and (max-width: 480px) {
            max-width: 100%;
        }
    }
`;

const Ingress = styled.div`
    padding-bottom: 85px;
    padding-top: 70px;
`;

const BottomBlock = styled.div`
    padding: 100px 0;
    background: #fafafa;
    @media screen and (max-width: 768px) {
        padding: 60px 20px;
    }
    a {
        width: 300px !important;
    }
`;

export const WhyVolvoService = () => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const { data, loading, error } = useQuery(GET_START, {
        variables: { language: currentLang }
    });

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    if (loading) {
        return <LoadingPage />;
    }

    if (error || !data) {
        console.log(error);
        return <Text>Failed to fetch data</Text>;
    }

    return (
        <Container className="start">
            <Hero>
                <Text variant="peary" as="h1">
                    {t("whyVolvoServicePage.heroTitle")}
                </Text>
                <Text
                    variant="columbus"
                    subStyle="emphasis"
                    className="subHeader"
                >
                    {t("whyVolvoServicePage.subHeader")}
                </Text>

                <Block extend={{ marginTop: "70px" }}>
                    <img
                        src={HeroImg}
                        alt=""
                        style={{
                            width: "100%",
                            maxWidth: "1000px"
                        }}
                    />
                </Block>

                <Ingress>
                    <Text
                        as="h2"
                        subStyle="emphasis"
                        extend={{ marginBottom: "20px" }}
                    >
                        För en bättre arbetsdag
                    </Text>
                    <Text variant="hillary" as="p">
                        Serviceappen är till för att förbättra och förenkla
                        arbetsflödet för personliga servicetekniker. Appen
                        kommunicerar med TACDIS & Organizer i realtid för att
                        möjliggöra en mobil arbetsroll där tekniker får relevant
                        & aktuell information vid rätt tid, på rätt plats.
                        <br />
                        <br />
                        Serviceappen utvecklas tillsammans med servicetekniker
                        och verkstadspersonal. Nya funktioner släpps
                        kontinuerligt baserat på feedback från våra användare.
                    </Text>
                </Ingress>
            </Hero>

            {data.pst.start.map((val, i) => {
                return (
                    <ImageBlock key={i}>
                        <Block
                            extend={{
                                marginBottom: "40px",
                                "@media (max-width: 768px)": {
                                    marginBottom: "30px"
                                }
                            }}
                        >
                            <img
                                src={val.image}
                                alt=""
                                style={{
                                    width: "100%",
                                    maxWidth: "600px"
                                }}
                            />
                        </Block>

                        <Text as="h2" subStyle="emphasis">
                            {val.title}
                        </Text>
                        <Text
                            variant="hillary"
                            as="p"
                            style={{ marginTop: "10px" }}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(val.description)
                                }}
                            />
                        </Text>
                    </ImageBlock>
                );
            })}
            <BottomBlock>
                <Text as="h3" subStyle="emphasis">
                    Hur lär man sig?
                </Text>
                <Text extend={{ margin: "7px 0 30px 0" }}>
                    Appen utvecklas tillsammans med servicetekniker, och Guiden
                    visar hur appen bäst används. När nya funktioner läggs till
                    uppdaterar vi hemsidan och meddelar alla servicetekniker.
                </Text>
                <Button
                    variant="outline"
                    intent="secondary"
                    href="/servicetekniker"
                >
                    TILL ANVÄNDARGUIDEN
                </Button>
            </BottomBlock>
        </Container>
    );
};
