import styled from "@emotion/styled";
import React, { useState } from "react";
import { Icon, Text } from "vcc-ui";
import { tableOfContentsWrangler } from "../../helpers/tableOfContentsWrangler";

export const TableOfContentsSection = ({ guide, scrollTo }) => {
    const categories = tableOfContentsWrangler(guide);

    return categories.map((category, idx) => (
        <Category
            key={`category-section-${idx}`}
            title={category.title}
            entries={category.entries}
            scrollTo={scrollTo}
        />
    ));
};

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Menu = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const Separator = styled.hr`
    width: 100%;
    max-width: 600px;

    border: 1px solid #bfc3c8;
`;

const Entries = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;

    * {
        margin: 0 !important;
        text-align: left;
    }
`;

const Category = ({ title, entries, scrollTo }) => {
    const [open, setOpen] = useState(false);

    return (
        <Container>
            <Menu onClick={() => setOpen(!open)}>
                <Text variant="hillary" subStyle="emphasis">
                    {title}
                </Text>
                {open ? (
                    <Icon type="navigation-chevronup-24" />
                ) : (
                    <Icon type="navigation-chevrondown-24" />
                )}
            </Menu>
            {open && (
                <Entries>
                    {entries.map((entry, idx) => (
                        <Text
                            key={`entry-${idx}`}
                            subStyle="inline-link"
                            extend={{ cursor: "pointer" }}
                            onClick={() => scrollTo(entry.title)}
                        >
                            {entry.title}
                        </Text>
                    ))}
                </Entries>
            )}
            <Separator />
        </Container>
    );
};
