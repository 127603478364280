import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text, TextArea, TextInput } from "vcc-ui";
import { FeedbackConfirm } from "../../components/FeedbackConfirm";
import { Hero } from "../../components/Hero";
import { SEND_FEEDBACK } from "../../graphql/mutations/sendFeedback";

const Container = styled.div`
    padding: 20px;
    padding-bottom: 78px;

    form {
        max-width: 500px;
        margin: auto;
        .inputWrapper {
            margin-bottom: 35px;
            button {
                margin-top: 15px;
            }
            p {
                font-size: 20px;
                margin-bottom: 6px;
            }
        }

        .feedback {
            text-align: center;
            max-width: 280px;
            margin: auto;
            margin-top: 14px;
        }
    }
`;

export const Feedback = () => {
    const { t } = useTranslation();

    const [createFeedback] = useMutation(SEND_FEEDBACK, {
        onCompleted() {
            setSent(true);
            setNameInput("");
            setCompany("");
            setDescriptionInput("");
        },
        onError(error) {
            console.log(error);
            setSendError(true);
        }
    });

    const [nameInput, setNameInput] = useState("");
    const [company, setCompany] = useState("");
    const [descriptionInput, setDescriptionInput] = useState("");
    const [formValid, setFormValid] = useState(true);
    const [formSent, setSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    const validateForm = () => {
        if (
            nameInput.length > 1 &&
            descriptionInput.length > 1 &&
            company.length > 1
        ) {
            setFormValid(true);
            sendForm();
        } else {
            setFormValid(false);
        }
    };

    const sendForm = () => {
        createFeedback({
            variables: {
                input: {
                    name: nameInput,
                    dealer: company,
                    message: descriptionInput
                }
            }
        });
    };

    return (
        <Container>
            <form>
                {sendError && (
                    <Text className="feedback">{t("FeedbackForm.error")}</Text>
                )}

                {formSent && <FeedbackConfirm />}

                {!formSent && (
                    <>
                        <Hero
                            title={t("FeedbackForm.header")}
                            text={t("FeedbackForm.text")}
                            marginBottom="91px"
                        />
                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("FeedbackForm.name")}
                            </Text>
                            <TextInput
                                name="name"
                                label={t("FeedbackForm.type")}
                                onChange={(e) => setNameInput(e.target.value)}
                                value={nameInput}
                                isValid={
                                    !formValid && nameInput.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("FeedbackForm.company")}
                            </Text>
                            <TextInput
                                name="company"
                                label={t("FeedbackForm.type")}
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                isValid={
                                    !formValid && company.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>

                        <div className="inputWrapper">
                            <Text as="p" subStyle="emphasis">
                                {t("FeedbackForm.feedback")}
                            </Text>
                            <TextArea
                                name="description"
                                label={t("FeedbackForm.type")}
                                onChange={(e) =>
                                    setDescriptionInput(e.target.value)
                                }
                                value={descriptionInput}
                                isValid={
                                    !formValid && descriptionInput.length <= 1
                                        ? false
                                        : true
                                }
                            />
                        </div>
                        <Button
                            intent="secondary"
                            onClick={() => validateForm()}
                        >
                            {t("FeedbackForm.button")}
                        </Button>
                    </>
                )}
            </form>
        </Container>
    );
};
