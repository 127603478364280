import { RefObject, useEffect, useState } from "react";

export function useElementScrollPos(ref: RefObject<HTMLDivElement>) {
    const [height, setHeight] = useState(0);
    const [y, setY] = useState(0);

    function handleScroll() {
        if (ref.current) {
            const domRect = ref.current.getBoundingClientRect();
            setY(domRect.top * -1);
        }
    }

    function handleResize() {
        if (ref.current && window !== undefined && window.visualViewport) {
            setHeight(ref.current.scrollHeight - window.visualViewport.height);
        }
    }

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return y / height;
}
