export const tableOfContentsWrangler = (guide) => {
    const categories = Array.from(
        guide.reduce((acc, cur) => acc.add(cur.category), new Set())
    );

    return categories.map((category) => ({
        title: category,
        entries: guide.filter((entry) => entry.category === category)
    }));
};
