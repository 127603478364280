/* eslint-disable react/jsx-pascal-case */
import styled from "@emotion/styled";
import React, { useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Block, Text } from "vcc-ui";
import slideImage1 from "../../../../assets/img-slide-1.png";
import slideImage2 from "../../../../assets/img-slide-2.png";
import slideImage3 from "../../../../assets/img-slide-3.png";
import slideImage4 from "../../../../assets/img-slide-4.1.png";
import { SliderButtons } from "../../SliderButtons";
import "./styles.scss";

const Wrapper = styled.div`
    padding-bottom: 100px;
    .slide {
        img {
            width: 100%;
            max-width: 270px;
            //max-width: 40%;
            @media screen and (max-width: 900px) {
                max-width: 200px;
            }
        }
    }
`;

const Navigation = styled.div`
    border-top: 1px solid #000;
    margin: 0;
    padding: 16px;
    position: sticky;
    bottom: 0;
    background-color: rgba(250, 250, 250, 0.95);
    z-index: 1;

    div:first-child {
        div {
            &:first-child {
                width: 42%;
            }
            &:last-child {
                margin-left: auto;
                width: 58%;
                text-align: right;
                p {
                    text-align: right;
                    &:last-of-type {
                        display: inline-block;
                        //width: min-content;
                    }
                }
            }
        }
    }
`;

export const MobileSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [swiper, setSwiper] = useState(null);

    return (
        <Wrapper id="mobile-slider">
            <Block
                style={{
                    marginBottom: "50px",
                    textAlign: "left",
                    paddingLeft: "20px"
                }}
            >
                <Text style={{ marginLeft: 0 }}>FUNKTIONER</Text>
                <Text
                    subStyle="emphasis"
                    variant="cook"
                    style={{ marginLeft: 0, marginTop: "20px" }}
                >
                    En app i ständig utveckling
                </Text>
            </Block>
            <Block>
                <Swiper
                    onSwiper={(swiper) => setSwiper(swiper)}
                    onSlideChange={(swiperCore) => {
                        const { activeIndex } = swiperCore;
                        /*console.log({
                        activeIndex,
                        snapIndex,
                        previousIndex,
                        realIndex
                    });*/
                        setCurrentSlide(activeIndex);
                    }}
                >
                    <SwiperSlide className="slide">
                        <img src={slideImage1} alt="slide1" />
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <img src={slideImage2} alt="slide2" />
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <img src={slideImage3} alt="slide3" />
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <img src={slideImage4} alt="slide4" />
                    </SwiperSlide>
                </Swiper>

                <Navigation>
                    {currentSlide === 0 && (
                        <Block style={{ display: "flex", textAlign: "left" }}>
                            <div>
                                <Text
                                    subStyle="emphasis"
                                    variant="hillary"
                                    as="p"
                                >
                                    Planera din arbetsdag
                                </Text>
                            </div>
                            <div>
                                <Text>Dagens schema i handen/fickan.</Text>
                            </div>
                        </Block>
                    )}

                    {currentSlide === 1 && (
                        <Block style={{ display: "flex", textAlign: "left" }}>
                            <div>
                                <Text
                                    subStyle="emphasis"
                                    variant="hillary"
                                    as="p"
                                >
                                    Hantera <br /> arbetsordrar
                                </Text>
                            </div>
                            <div>
                                <Text>Var förberedd när kunden kommer.</Text>
                            </div>
                        </Block>
                    )}
                    {currentSlide === 2 && (
                        <Block style={{ display: "flex", textAlign: "left" }}>
                            <div>
                                <Text
                                    subStyle="emphasis"
                                    variant="hillary"
                                    as="p"
                                >
                                    Återkoppla mot <br /> kunden
                                </Text>
                            </div>
                            <div>
                                <Text>Kontakta kunden direkt.</Text>
                            </div>
                        </Block>
                    )}
                    {currentSlide === 3 && (
                        <Block style={{ display: "flex", textAlign: "left" }}>
                            <div>
                                <Text
                                    subStyle="emphasis"
                                    variant="hillary"
                                    as="p"
                                >
                                    + På gång
                                </Text>
                            </div>
                            <div>
                                <Text>
                                    vi jobbar för fullt med att rulla ut fler
                                    värdefulla funktioner som{" "}
                                    <Text subStyle="emphasis">Sökfunktion</Text>
                                    ,{" "}
                                    <Text subStyle="emphasis">
                                        Mottagningsbevis
                                    </Text>{" "}
                                    och{" "}
                                    <Text subStyle="emphasis">
                                        Tilläggsjobb
                                    </Text>
                                    .
                                </Text>
                            </div>
                        </Block>
                    )}
                    <SliderButtons
                        currentSlide={currentSlide}
                        slidesTotal={4}
                        setCurrentSlide={setCurrentSlide}
                        swiper={swiper}
                    />
                </Navigation>
            </Block>
        </Wrapper>
    );
};
